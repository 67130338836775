import { FC, useState } from "react";
import { Trans } from "react-i18next";
import { PlusThin } from "assets/svg";
import { isEmpty } from "lodash";
import { useAppSelector } from "store/hooks";
import { companyMetadataSelector, userMetadataSelector } from "store/selectors";

import { BankAccountVerifyStatus } from "types/BankAccountVerifyStatus";
import { DefaultBankAccountType, UserRole } from "types/BETypes";
import { hasAdminRights } from "permissions/helpers/shared";
import {
  isExternalBankAccountEligibleForDefault,
  isThisLastVerifiedExternalBankAccount,
} from "helpers/bankAccount/externalBankAccount";
import MicroDepositInfoModal from "components/MicroDepositInfoModal";

import { GetPlaidBankAccountsResponseDto } from "utils/swagger_react_query";

import { BankAccountInfoRow } from "../BankAccountInfoRows";
import {
  renderBankInUseLink,
  renderRemoveBankAccountBtn,
  renderVerifyBtn,
} from "../BankAccountInfoRows/renderElements";
import { ExternalBankAccountInfoRowColumn } from "../BankAccountInfoRows/types";
import {
  BankAccountListRow,
  BankAccountsListWrapper,
  ConnectBankAccountLinkBtn,
  ConnectBankBtn,
  EmptyStateContainer,
  ESIcon,
  ESMessage,
  ESTitle,
  StyledH2,
  Wrapper,
} from "./styles";

export interface IBankAccountSectionProps {
  bankAccounts: GetPlaidBankAccountsResponseDto[];
  onConnectBankAccount?: () => void;
  onRemoveBankAccount: (bankAccountId: string) => void;
  onVerifyBankAccount?: (bankAccountId: string, accessToken: string) => void;
  onBankInUseClick?: (bankAccountId: string) => void;
  onChangeDefaultBank?: (id: string) => void;
  defaultBankAccountId?: string;
  showConnectBankAccountButtonBelowList?: boolean;
}

const ExternalBankAccountsSection: FC<IBankAccountSectionProps> = ({
  bankAccounts,
  onConnectBankAccount,
  onRemoveBankAccount,
  onVerifyBankAccount,
  onBankInUseClick,
  onChangeDefaultBank,
  defaultBankAccountId,
  showConnectBankAccountButtonBelowList,
}) => {
  const currentUser = useAppSelector(userMetadataSelector);
  const currentCompany = useAppSelector(companyMetadataSelector);
  const commonTranslationPrefix = `components.bank_account_components`;
  const translationPrefix = `${commonTranslationPrefix}.external_accounts_list_section`;

  const [isMicroDepositInfoModalOpen, setMicroDepositInfoModal] = useState<boolean>(false);
  const [microDepositInfoModalType, setMicroDepositInfoModalType] =
    useState<BankAccountVerifyStatus>();

  const onPendingVerificationClick = (verificationStatus: BankAccountVerifyStatus) => {
    setMicroDepositInfoModalType(verificationStatus);
    setMicroDepositInfoModal(true);
  };

  const getActionsColumn = (item: GetPlaidBankAccountsResponseDto) => {
    const canRemoveBankAccount = () => {
      if (hasAdminRights(currentUser)) {
        if (currentCompany?.defaultBankAccountType === DefaultBankAccountType.EXTERNAL_ACCOUNT) {
          return !item.isDefault;
        }
        if (isThisLastVerifiedExternalBankAccount(item.bankAccountId || "", bankAccounts))
          return false;
      }

      return true;
    };

    const shouldShowVerifyBtn =
      !!item?.accessToken &&
      (item?.verificationStatus === BankAccountVerifyStatus.PENDING_MANUAL_VERIFICATION ||
        !!item?.renewLoginRequired);

    const shouldShowBankInUseBtn =
      !!onBankInUseClick &&
      currentUser?.defaultPayDistribution?.some((it) => it.id === item.bankAccountId);

    return (
      <>
        {shouldShowBankInUseBtn && renderBankInUseLink(() => onBankInUseClick(item.bankAccountId))}
        {shouldShowVerifyBtn &&
          onVerifyBankAccount &&
          renderVerifyBtn(
            () => onVerifyBankAccount?.(item.bankAccountId, item?.accessToken || ""),
            item?.renewLoginRequired ?? false,
          )}
        {canRemoveBankAccount() &&
          renderRemoveBankAccountBtn(() => onRemoveBankAccount(item.bankAccountId), item)}
      </>
    );
  };

  const renderBankAccountsList = () => {
    return bankAccounts?.map((item) => {
      const columns = [
        ExternalBankAccountInfoRowColumn.NAME,
        ExternalBankAccountInfoRowColumn.BANK_ACCOUNT_NAME,
        ExternalBankAccountInfoRowColumn.ACCOUNT_NUMBER,
        ExternalBankAccountInfoRowColumn.TYPE,
        ExternalBankAccountInfoRowColumn.STATUS,
      ];
      if (
        currentUser?.lastActiveRole !== UserRole.EMPLOYEE &&
        isExternalBankAccountEligibleForDefault(item)
      ) {
        columns.unshift(ExternalBankAccountInfoRowColumn.DEFAULT_BANK_RADIO_BTN);
      }
      return (
        <BankAccountListRow key={item.bankAccountId}>
          <BankAccountInfoRow
            bankAccountInfo={item}
            onPendingVerificationClick={onPendingVerificationClick}
            actionsColumn={getActionsColumn(item)}
            onChangeDefaultBank={(id) => onChangeDefaultBank?.(id)}
            defaultBankAccountId={defaultBankAccountId}
            displayedColumns={columns}
          />
        </BankAccountListRow>
      );
    });
  };

  return (
    <Wrapper>
      <StyledH2>
        <Trans i18nKey={`${translationPrefix}.title`} />
      </StyledH2>

      {isEmpty(bankAccounts) ? (
        <>
          <EmptyStateContainer data-testid={"external_bank_account_empty_state_text"}>
            <ESIcon />
            <Trans
              i18nKey={`${translationPrefix}.empty_state.message`}
              components={{
                1: <ESTitle />,
                2: <ESMessage />,
              }}
            />
            {onConnectBankAccount && (
              <ConnectBankBtn
                onClick={onConnectBankAccount}
                data-testid={`connect_bank_account_button`}
              >
                <PlusThin />
                <Trans i18nKey={`${translationPrefix}.empty_state.button`} />
              </ConnectBankBtn>
            )}
          </EmptyStateContainer>
        </>
      ) : (
        <BankAccountsListWrapper>{renderBankAccountsList()}</BankAccountsListWrapper>
      )}

      {showConnectBankAccountButtonBelowList && onConnectBankAccount && (
        <ConnectBankAccountLinkBtn
          onClick={onConnectBankAccount}
          data-testid={`connect_bank_account_button_below_list`}
        >
          <PlusThin />
          <Trans i18nKey={`${translationPrefix}.connect_bank_account_link_btn`} />
        </ConnectBankAccountLinkBtn>
      )}

      {microDepositInfoModalType && (
        <MicroDepositInfoModal
          isOpen={isMicroDepositInfoModalOpen}
          onClose={() => setMicroDepositInfoModal(false)}
          onBtnClick={() => setMicroDepositInfoModal(false)}
          verificationStatus={microDepositInfoModalType}
        />
      )}
    </Wrapper>
  );
};

export default ExternalBankAccountsSection;
