import styled from "styled-components";

interface IWrapper {
  disabled?: boolean;
}

export const Wrapper = styled.div<IWrapper>`
  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.6;
    pointer-events: none;
    cursor: auto;
  `}
`;

export const LabelWrapper = styled.label`
  display: flex;
  align-items: center;
`;

export const Label = styled.span`
  margin-left: 10px;
  font-size: ${({ theme }) => theme.size.caption};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.textAlt};
`;

export const RadioInput = styled.input`
  display: none;
`;

export const Radio = styled.div`
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.colors.radioButtonBgInactive};
  cursor: pointer;
  position: relative;

  &.checked {
    border: 2px solid ${({ theme }) => theme.colors.radioButtonBg};

    & > div {
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: ${({ theme }) => theme.colors.radioButtonBg};
    }
  }
`;

export const RadioAlt = styled(Radio)`
  border: 1px solid ${({ theme }) => theme.colors.radioButtonBgAltInactive} !important;

  &.checked {
    border: 6px solid ${({ theme }) => theme.colors.radioButtonBorderAlt} !important;

    & > div {
      background: ${({ theme }) => theme.colors.radioButtonBgAlt} !important;
    }
  }
`;
