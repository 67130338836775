import { EKycResponseType } from "components/CompanyBankAccountComponents/employee/KYCVerification/types";

import { GetBusinessStructureResponseDto } from "utils/swagger_react_query";

export interface IBusinessOwnersStructureProps {
  widgetType: BusinessOwnersStructureWidgetType;
  emphasizedContainer?: boolean;
  className?: string;
  fetchDataOnMount?: boolean;
  onStatusSectionPrimaryBtnClick?: (
    status: EKycResponseType,
    businessStructure: GetBusinessStructureResponseDto,
  ) => void;
  onKybSuccess?: () => void;
  onBackClick?: () => void;
  showResponseTypeSectionOnMount?: boolean;
}

export type IUseBusinessOwnerStructureParams = Pick<
  IBusinessOwnersStructureProps,
  "fetchDataOnMount"
>;

export enum BusinessOwnersStructureWidgetType {
  SETUP = "SETUP",
  VIEW = "VIEW",
}

export interface IUseKybVerificationParams
  extends Pick<
    IBusinessOwnersStructureProps,
    "onKybSuccess" | "onStatusSectionPrimaryBtnClick" | "showResponseTypeSectionOnMount"
  > {
  businessStructure: GetBusinessStructureResponseDto | null;
}
