import { FC, ReactNode } from "react";
import { Trans } from "react-i18next";

import { StyledTooltip } from "./styles";

interface IProps {
  id: string;
  className?: string;
  isBlocked?: boolean;
  tooltipContent?: ReactNode;
  children?: ReactNode;
}

const PermissionBlocker: FC<IProps> = ({
  id,
  className,
  isBlocked = false,
  tooltipContent,
  children,
}) => {
  const defaultTranslationPrefix = `banking_pages.shared.components.permission_blocker`;
  const defaultTooltipContent = <Trans i18nKey={`${defaultTranslationPrefix}.message`} />;

  const _tooltipContent = isBlocked ? tooltipContent || defaultTooltipContent : null;
  return (
    <StyledTooltip id={id} className={className} tooltipContent={_tooltipContent}>
      {children}
    </StyledTooltip>
  );
};

export default PermissionBlocker;
