import { ModalAlert } from "assets/svg";
import styled, { css } from "styled-components";

import CopyText from "components/CopyText";

import { Body1, Body2, CustomLink, H2, LinkTextMixin, SectionAltRounded } from "uikit";
import { FullSectionLoaderContainer } from "uikit/Loader/styles";

export const ScrollableContainerMixin = css`
  overflow-y: auto;
  flex: 1;
`;

export const ScrollableContainer = styled.div`
  ${ScrollableContainerMixin}
`;

export const Header = styled.div`
  margin-bottom: 24px;
`;

export const HTitle = styled(H2)`
  margin-bottom: 4px;
`;

export const HDescription = styled(Body2)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.textAlt};

  svg {
    width: 16px;
    height: 16px;
    margin-left: 10px;
  }
`;

export const FormSection = styled(SectionAltRounded)`
  position: relative;
  margin-bottom: 0;
  overflow: auto;
`;

export const FSHInnerTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

export const StepText = styled.span`
  color: ${({ theme }) => theme.colors.textInactiveAlt2};
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  line-height: 18.2px;
`;

export const CheckboxWrapper = styled.div`
  width: 100%;
  margin-top: 24px;
`;

export const CheckboxText = styled.p`
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  color: ${({ theme }) => theme.colors.text};
  line-height: 1.8;
`;

export const CheckboxTextLink = styled(CustomLink)``;

export const StyledLoaderContainer = styled(FullSectionLoaderContainer)``;

export const TotalStepsNumber = styled.div`
  color: ${({ theme }) => theme.colors.title};
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  line-height: 20.8px;
  margin-top: 4px;
  margin-bottom: 32px;
`;

export const ResponseStatusContainer = styled(SectionAltRounded)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const ResponseStatusTitle = styled(H2)`
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  font-size: ${({ theme }) => theme.size.title};
  margin-top: 24px;
  margin-bottom: 12px;
`;

export const ResponseStatusMessage = styled(Body1)`
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-size: ${({ theme }) => theme.size.body1};
  color: ${({ theme }) => theme.colors.textAlt};

  svg {
    margin: 0 6px;

    path {
      stroke: ${({ theme }) => theme.colors.textAlt4};
    }
  }
`;

export const LinkText = styled.span`
  ${LinkTextMixin};
  font-size: ${({ theme }) => theme.size.body1};
`;

export const StyledCopyText = styled(CopyText)`
  margin-top: 12px;
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  color: ${({ theme }) => theme.colors.textAlt4};
  cursor: pointer;

  span:nth-child(1) {
    margin-right: 12px;
  }
`;

export const ErrorMessageBox = styled.div`
  display: flex;
  padding: 8px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.dangerAlt3};
  margin-bottom: 24px;
  width: 390px;
`;

export const EMIcon = styled(ModalAlert)`
  width: 24px;
  height: 24px;
  margin-right: 8px;
  fill: ${({ theme }) => theme.colors.danger};

  path {
    &#Vector {
      stroke: ${({ theme }) => theme.colors.danger};
    }
  }
`;

export const EMText = styled(Body1)`
  max-width: 342px;
  color: ${({ theme }) => theme.colors.danger};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;
