import { FC } from "react";
import { Trans } from "react-i18next";
import PromoManImage from "assets/png/promo_man.png";
import { ArrowIconSmall } from "assets/svg";
import { Formik } from "formik";

import { Loader } from "uikit";

import {
  BackBtn,
  BtnsWrapper,
  BtnsWrapperOuter,
  SubmitBtn,
} from "../../CompanyBankAccountCreationModal/styles";
import { renderErrorMessage } from "../../components/renderElements";
import { HDescription, Header, HTitle, StyledLoaderContainer } from "../../components/styles";
import { EarlyPayForm, EarlyPayFormType, EarlyPayValidationSchema } from "./components";
import { IEarlyPayProps } from "./types";
import { useEarlyPay } from "./useEarlyPay";
import {
  ContainerInner,
  FormContainer,
  LeftSection,
  PromoImage,
  RightSection,
  StyledSectionAlt,
} from "./styles";

const EarlyPay: FC<IEarlyPayProps> = ({ className, onBackBtnClick, onSubmitSuccess }) => {
  const originTranslationPrefix = "components.company_bank_account_components";
  const translationPrefix = `${originTranslationPrefix}.early_pay_form`;
  const { isSubmitting, errorMessage, initValues, handleSubmitEarlyPay } = useEarlyPay({
    onSubmitSuccess,
  });

  return (
    <Formik<EarlyPayFormType>
      initialValues={initValues}
      validationSchema={EarlyPayValidationSchema}
      onSubmit={() => {}}
    >
      {(formikProps) => (
        <FormContainer
          className={className}
          onSubmit={(e) => {
            const { values, validateForm } = formikProps;
            e.preventDefault();
            handleSubmitEarlyPay({ values, validateForm });
          }}
        >
          <ContainerInner>
            <LeftSection>
              <Header>
                <HTitle>
                  <Trans i18nKey={`${translationPrefix}.title`} />
                </HTitle>
                <HDescription>
                  <Trans i18nKey={`${translationPrefix}.description`} />
                </HDescription>
              </Header>

              {renderErrorMessage(errorMessage)}

              <StyledSectionAlt>
                {isSubmitting && (
                  <StyledLoaderContainer>
                    <Loader />
                  </StyledLoaderContainer>
                )}

                <EarlyPayForm />
              </StyledSectionAlt>
            </LeftSection>

            <RightSection>
              <PromoImage src={PromoManImage} />
            </RightSection>
          </ContainerInner>

          <BtnsWrapperOuter>
            <BtnsWrapper>
              <BackBtn
                onClick={onBackBtnClick}
                type={"button"}
                disabled={isSubmitting}
                id={"cbacm-early-form-cancel-button"}
                data-testid={"cbacm-early-form-cancel-button"}
              >
                <Trans i18nKey="buttons.cancel" />
              </BackBtn>

              <SubmitBtn
                type={"submit"}
                id={"cbacm-early-pay-form-submit-button"}
                data-testid={"cbacm-early-pay-form-submit-button"}
              >
                <Trans i18nKey={"buttons.submit"} />
                <ArrowIconSmall />
              </SubmitBtn>
            </BtnsWrapper>
          </BtnsWrapperOuter>
        </FormContainer>
      )}
    </Formik>
  );
};

export default EarlyPay;
