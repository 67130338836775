import { UserRole } from "types/BETypes";

import { GetCompanyByIdResponseDto, UserProfileResponseDto } from "utils/swagger_react_query";

import { isKybAccepted } from "./kyb";
import { isKycAccepted } from "./kyc";

export const shouldFetchInternalBankAccounts = (
  currentUser: UserProfileResponseDto | undefined | null,
  currentCompany: GetCompanyByIdResponseDto | undefined | null,
) => {
  if (currentUser?.lastActiveRole === UserRole.EMPLOYEE) {
    return isKycAccepted(currentUser);
  }
  return isKybAccepted(currentCompany);
};
