import { BankAccountVerifyStatus } from "types/BankAccountVerifyStatus";

import { GetPlaidBankAccountsResponseDto } from "utils/swagger_react_query";

export const isExternalBankAccountEligibleForDefault = (
  bankAccount: GetPlaidBankAccountsResponseDto,
): boolean => {
  const nonDefaultStatuses = [
    BankAccountVerifyStatus.PENDING_AUTOMATIC_VERIFICATION,
    BankAccountVerifyStatus.PENDING_MANUAL_VERIFICATION,
    BankAccountVerifyStatus.VERIFICATION_EXPIRED,
    BankAccountVerifyStatus.VERIFICATION_FAILED,
  ];

  return !nonDefaultStatuses.includes(bankAccount.verificationStatus as BankAccountVerifyStatus);
};

export const isExternalBankAccountEligibleForTransfer = (
  bankAccount: GetPlaidBankAccountsResponseDto,
): boolean => {
  const nonTransferStatuses = [
    BankAccountVerifyStatus.PENDING_MANUAL_VERIFICATION,
    BankAccountVerifyStatus.VERIFICATION_EXPIRED,
    BankAccountVerifyStatus.VERIFICATION_FAILED,
  ];

  return !nonTransferStatuses.includes(bankAccount.verificationStatus as BankAccountVerifyStatus);
};

export const isThisLastVerifiedExternalBankAccount = (
  bankAccountId: string,
  bankAccounts: GetPlaidBankAccountsResponseDto[],
): boolean => {
  const verifiedStatus = [
    BankAccountVerifyStatus.AUTOMATICALLY_VERIFIED,
    BankAccountVerifyStatus.MANUALLY_VERIFIED,
    BankAccountVerifyStatus.INSTANTLY_VERIFIED,
  ];

  const verifiedBankAccounts = bankAccounts.filter((bankAccount) =>
    verifiedStatus.includes(bankAccount.verificationStatus as BankAccountVerifyStatus),
  );

  return (
    verifiedBankAccounts.length === 1 && verifiedBankAccounts[0].bankAccountId === bankAccountId
  );
};
