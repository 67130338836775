import moment from "moment";

import { CompanyEntityType } from "types/BETypes";
import { companyTypeSelectOptions } from "constants/shared/companyTypes";
import { getCompanyTypeSelectOption } from "helpers/shared/companyType";
import { getStateSelectOption } from "helpers/shared/states";

import {
  BusinessAccountData,
  BusinessInfoResDto,
  BusinessStructureCompanyResponseDto,
  UpdateBusinessStructureBusinessDto,
  UserResponseDto,
} from "utils/swagger_react_query";

import { BusinessAccountCompanyInfoFormType } from "./components/BusinessAccountCompanyInfoForm/validationSchema";

export const convertBusinessFormDataToBusinessAccountData = (
  formData: BusinessAccountCompanyInfoFormType,
): BusinessAccountData => {
  return {
    companyName: formData.companyName,
    operatingName: formData.operatingName,
    dateOfFoundation: moment(formData.businessFormationDate)?.toISOString(),
    formationState: formData.stateOfIncorporation.value,
    businessStructure: formData.businessStructure.value,
    phoneNumber: formData.businessPhone,
    ein: formData.ein,
    address: formData.address,
    address2: formData.suite || undefined,
    city: formData.city,
    state: formData.state.value,
    zip: formData.zip,
    email: formData.email,
  };
};

export const getBusinessInfoFormDataInitValues = (
  data: BusinessInfoResDto | null,
): BusinessAccountCompanyInfoFormType => {
  const businessStructure = companyTypeSelectOptions[0];

  return {
    companyName: data?.name || "",
    operatingName: "",
    businessFormationDate: moment().toDate(),
    stateOfIncorporation: getStateSelectOption(),
    businessStructure: businessStructure,
    businessPhone: "",
    ein: data?.ein || "",
    address: data?.address || "",
    city: data?.city || "",
    state: getStateSelectOption(data?.state),
    zip: data?.zip || "",
    email: "",
  };
};

export const convertAlreadyExistingBusinessInfoToFormData = (
  data: BusinessStructureCompanyResponseDto,
): BusinessAccountCompanyInfoFormType => {
  const businessType = getCompanyTypeSelectOption(data.businessStructure as CompanyEntityType);

  return {
    companyName: data?.companyName || "",
    operatingName: data.operatingName,
    businessFormationDate: moment().toDate(),
    stateOfIncorporation: getStateSelectOption(data?.formationState as UserResponseDto["state"]),
    businessStructure: businessType,
    businessPhone: data.phoneNumber,
    ein: data?.ein || "",
    address: data?.address || "",
    city: data?.city || "",
    state: getStateSelectOption(data?.state),
    zip: data?.zip || "",
    email: data.email || "",
  };
};

export const convertBusinessAccountCompanyFormToUpdateBusinessDto = (
  data: BusinessAccountCompanyInfoFormType,
): UpdateBusinessStructureBusinessDto => {
  return {
    companyName: data.companyName || "",
    operatingName: data.operatingName || "",
    dateOfFoundation: moment(data.businessFormationDate).toISOString() || "",
    formationState: data.stateOfIncorporation.value || "",
    businessStructure: data.businessStructure.value || "",
    phoneNumber: data.businessPhone || "",
    ein: data.ein || "",
    address: data.address || "",
    address2: data?.suite || undefined,
    city: data.city || "",
    state: data.state.value || "",
    zip: data.zip || "",
    email: data.email,
  };
};
