import { ReactNode } from "react";

import { UserRole } from "types/BETypes";
import { getName, getUserRoleName } from "helpers";

import { IAsyncUserSearchInputRenderUserOption } from "./types";
import {
  OptionCell,
  OptionUserEmailText,
  OptionUserEmailTextHighlighted,
  OptionUserFullNameText,
  OptionUserRoleText,
  StyledOption,
} from "./styles";

const asyncUserSearchRoleCell = (role: UserRole) => {
  return (
    <OptionCell>
      <OptionUserRoleText className={`${role}`}>{getUserRoleName(role)}</OptionUserRoleText>
    </OptionCell>
  );
};

export const renderAsyncUserSearchNameWithEmail = (
  name: string | ReactNode,
  email: ReactNode | undefined,
) => {
  return (
    <OptionCell className="column max_content">
      <OptionUserFullNameText>{name}</OptionUserFullNameText>
      {email && <OptionUserEmailText>{email}</OptionUserEmailText>}
    </OptionCell>
  );
};

export const renderAsyncUserSearchHighlightedText = (
  text: string | undefined,
  highlight: string,
): ReactNode => {
  if (!text) return;
  const regex = new RegExp(`(${highlight})`, "gi");
  const parts = text?.split(regex);
  return parts?.map((part, index) =>
    part.toLowerCase() === highlight.toLowerCase() ? (
      <OptionUserEmailTextHighlighted key={index}>{part}</OptionUserEmailTextHighlighted>
    ) : (
      part
    ),
  );
};

export const renderAsyncUserSearchInputOptionBase = (
  props: IAsyncUserSearchInputRenderUserOption,
) => {
  const { data, innerRef, innerProps, onOptionClick } = props;

  return (
    <StyledOption
      ref={innerRef}
      {...innerProps}
      data-testid="async-user-search-result-option"
      onClick={() => onOptionClick?.(data)}
    >
      {props?.leftCell}
      {props?.rightCell}
    </StyledOption>
  );
};

export const renderAsyncUserSearchInputOptionWithRole = (
  props: IAsyncUserSearchInputRenderUserOption,
) => {
  const { data, searchValue } = props;
  const name = data?.firstName && data?.lastName ? getName(data) : "-";
  return renderAsyncUserSearchInputOptionBase({
    ...props,
    leftCell: renderAsyncUserSearchNameWithEmail(
      name,
      renderAsyncUserSearchHighlightedText(data?.email, searchValue || ""),
    ),
    rightCell: asyncUserSearchRoleCell(data?.lastActiveRole as UserRole),
  });
};
