import { BankingType, UserRole } from "types/BETypes";
import { CardForm } from "types/CardShipping";
import { EStatesShort, StateSelectOptions } from "constants/shared";
import { getPhoneNumberOrNull } from "helpers";
import { getStateSelectOption } from "helpers/shared/states";
import { CompanyCardShippingDetailsFormType } from "components/CompanyBankAccountComponents/CompanyCardShippingDetails";

import {
  BankCardResDto,
  BusinessStructureKeyManagerResponseDto,
  BusinessStructureOwnerResponseDto,
  IssueBankCardReqDto,
  UserResponseDto,
} from "utils/swagger_react_query";

export const userDetailsToShippingDetailsFormInitialValues = (
  userDetails: Partial<UserResponseDto> | undefined | null,
): CompanyCardShippingDetailsFormType => {
  const state = StateSelectOptions.find((state) => state.value === userDetails?.state) || {
    label: "",
    value: "",
  };

  const result: CompanyCardShippingDetailsFormType = {
    firstName: userDetails?.firstName || "",
    lastName: userDetails?.lastName || "",
    address: userDetails?.address || "",
    city: userDetails?.city || "",
    state: state,
    zip: userDetails?.zip || "",
    suite: userDetails?.suite || "",
    phone: getPhoneNumberOrNull(userDetails?.phone || "")!,
  };

  return result;
};

export const convertBusinessStructurePersonDataToShippingDetailsForm = (
  userDetails:
    | BusinessStructureKeyManagerResponseDto
    | BusinessStructureOwnerResponseDto
    | undefined,
): CompanyCardShippingDetailsFormType => {
  return {
    firstName: userDetails?.firstName || "",
    lastName: userDetails?.lastName || "",
    address: userDetails?.address || "",
    city: userDetails?.city || "",
    state: getStateSelectOption(userDetails?.state),
    zip: userDetails?.zip || "",
    suite: userDetails?.address2 || "",
    phone: getPhoneNumberOrNull(userDetails?.phoneNumber || "")!,
  };
};

const convertShippingFormDataToShippingDetailsData = (
  values: CompanyCardShippingDetailsFormType,
): IssueBankCardReqDto["shipping"] => {
  return {
    address: {
      addressLine1: values.address,
      addressLine2: values.suite || undefined,
      city: values.city,
      state: values.state.value as EStatesShort,
      postalCode: values.zip,
      countryCode: "US",
    },
    recipientFirstName: values.firstName,
    recipientLastName: values.lastName,
    phoneNumber: values.phone,
  };
};

export const convertShippingFormDataToReqPhysicalCardPayload = (
  userId: string,
  role: UserRole,
  values: CompanyCardShippingDetailsFormType,
): IssueBankCardReqDto => {
  const bankingTypeByRole: BankingType =
    role === UserRole.EMPLOYEE ? BankingType.PERSONAL : BankingType.BUSINESS;
  return {
    form: CardForm.PHYSICAL,
    shipping: convertShippingFormDataToShippingDetailsData(values),
    bankingType: bankingTypeByRole,
    userId: userId || "",
  };
};

export const convertShippingFormDataToReqVirtualCardPayload = (
  userId: string,
  role: UserRole,
): IssueBankCardReqDto => {
  const bankingTypeByRole: BankingType =
    role === UserRole.EMPLOYEE ? BankingType.PERSONAL : BankingType.BUSINESS;
  return {
    form: CardForm.VIRTUAL,
    bankingType: bankingTypeByRole,
    userId: userId,
  };
};

export const getSelectedCardholderIssuedCards = (
  cardHolderId: string,
  bankCardsList: BankCardResDto[] | undefined,
) => {
  return bankCardsList?.filter((card) => card.ownerInfo?.userId === cardHolderId) || [];
};
