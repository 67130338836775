import { isCardActiveOrUnactivated } from "helpers/bankCards";
import { isKybAccepted } from "helpers/shared/kyb";

import { BankCardResDto, GetCompanyByIdResponseDto, MfaFactorDto } from "utils/swagger_react_query";

export const isAdminSetupComplete = (
  companyDetails: GetCompanyByIdResponseDto | undefined | null,
  bankCards: BankCardResDto[] | undefined,
  factors: MfaFactorDto[] | undefined,
): boolean => {
  const kybIsAccepted = isKybAccepted(companyDetails);
  const hasVerifiedFactor = !!factors?.some((factor) => factor.isVerified);
  const hasBusinessStructureMemberAlreadyOrderedCard = bankCards?.some(isCardActiveOrUnactivated);

  return !!kybIsAccepted && !!hasBusinessStructureMemberAlreadyOrderedCard && !!hasVerifiedFactor;
};
