import { isEmpty } from "lodash";
import moment from "moment";

import { KycStatus } from "types/BETypes";
import { EStates, EStatesShort, StateSelectOptions } from "constants/shared";
import { getPhoneNumberOrNull } from "helpers";
import { isKycAccepted } from "helpers/shared/kyc";
import { FAILED_KYC_USER_DATA_LOCAL_STORAGE_KEY } from "components/CompanyBankAccountComponents/CompanyBankAccountCreationModal/constants";

import {
  KycVerifyReqDto,
  UserProfileResponseDto,
  UserResponseDto,
} from "utils/swagger_react_query";

import { KYCProfileFormType } from "./KYCProfileForm";
import { EKycResponseType } from "./types";

export const userDetailsToKycProfileFormInitialValues = (
  userDetails: UserResponseDto | undefined | null,
): KYCProfileFormType => {
  const state = StateSelectOptions.find((state) => state.value === userDetails?.state) || {
    label: EStates.AK,
    value: EStatesShort.AK,
  };

  const result: KYCProfileFormType = {
    firstName: userDetails?.firstName || "",
    lastName: userDetails?.lastName || "",
    phone: getPhoneNumberOrNull(userDetails?.phone || "")!,
    address: userDetails?.address || "",
    city: userDetails?.city || "",
    state: state,
    zip: userDetails?.zip || "",
    birthDate: userDetails?.birthDate ? new Date(userDetails?.birthDate) : (null as any),
    suite: userDetails?.suite || "",
    socialSecurityNumber: userDetails?.socialSecurityNumber || "",
  };

  return result;
};

export const profileFormValuesToKycRequestPayload = (
  values: KYCProfileFormType,
): KycVerifyReqDto => {
  const result: KycVerifyReqDto = {
    phone: values.phone,
    address: values.address,
    city: values.city,
    state: values.state.value,
    zip: values.zip,
    birthDate: moment(values.birthDate).toISOString(),
    suite: values.suite || undefined,
    socialSecurityNumber: values.socialSecurityNumber,
  };

  return result;
};

export const setFailedKycUserDataToLocalStorage = (data: KycVerifyReqDto) => {
  localStorage.setItem(FAILED_KYC_USER_DATA_LOCAL_STORAGE_KEY, JSON.stringify(data));
};

export const getFailedKycUserDataFromLocalStorage = (): KycVerifyReqDto | undefined => {
  const data = localStorage.getItem(FAILED_KYC_USER_DATA_LOCAL_STORAGE_KEY);
  return data ? JSON.parse(data) : undefined;
};

export const removeFailedKycUserDataFromLocalStorage = () => {
  localStorage.removeItem(FAILED_KYC_USER_DATA_LOCAL_STORAGE_KEY);
};

export const getCombinedUserDataWithFailedKycData = (
  userData: UserResponseDto | undefined | null,
  failedKycData: KycVerifyReqDto | undefined,
): UserResponseDto | undefined | null => {
  if (!failedKycData || !userData) return userData;

  const result: UserResponseDto = {
    ...userData,
    phone: failedKycData.phone,
    address: failedKycData.address || null,
    city: failedKycData.city || null,
    state: failedKycData.state || null,
    zip: failedKycData.zip || null,
    birthDate: failedKycData.birthDate || null,
    suite: failedKycData.suite,
    socialSecurityNumber: failedKycData.socialSecurityNumber || null,
  };

  return result;
};

export const getKycResponseType = (
  user?: UserProfileResponseDto | null | undefined,
  kycVerificationPayload?: KycVerifyReqDto,
  KycVerificationPreviousPayload?: KycVerifyReqDto,
): EKycResponseType | undefined => {
  const { kycStatus, hasPersonalBankAccount } = user || {};
  if (!kycStatus && !hasPersonalBankAccount) return undefined;
  const isDataChanged =
    JSON.stringify(KycVerificationPreviousPayload) !== JSON.stringify(kycVerificationPayload);

  if (
    !isDataChanged &&
    kycStatus === KycStatus.REJECTED &&
    !isEmpty(kycVerificationPayload) &&
    !isEmpty(KycVerificationPreviousPayload)
  ) {
    return EKycResponseType.INCORRECT_INFO;
  }

  if (isKycAccepted(user)) {
    return EKycResponseType.SUCCESS;
  }

  if (
    [
      KycStatus.PROVIDER_FAILURE,
      KycStatus.REJECTED,
      KycStatus.UNVERIFIED,
      KycStatus.VENDOR_ERROR,
    ].includes(kycStatus as KycStatus)
  ) {
    return EKycResponseType.ERROR;
  }

  if (
    [KycStatus.PENDING, KycStatus.PROVISIONAL, KycStatus.REVIEW].includes(kycStatus as KycStatus)
  ) {
    return EKycResponseType.VERIFICATION_IS_TAKING_TOO_LONG;
  }
};
