import styled from "styled-components";

import { Body1, Body3 } from "uikit";

export const VerificationErrorsContainer = styled.div`
  padding-top: 16px;
  border-top: 1px solid ${({ theme }) => theme.colors.headerBorder};
  display: flex;
`;

export const VerificationErrorTitle = styled(Body1)`
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
`;

export const VerificationErrorList = styled.div`
  margin-left: 32px;
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const VerificationErrorItem = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  background-color: ${({ theme }) => theme.colors.dangerAlt3};
  border-radius: 8px;
  padding: 12px;

  svg {
    width: 20px;
    height: 20px;
  }

  &:not(:last-child) {
    margin-bottom: 12px;
  }
`;

export const VerificationErrorItemText = styled(Body3)`
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  margin-left: 12px;
`;

export const VerificationErrorItemTextBold = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;
