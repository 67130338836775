import {
  BusinessStructureKeyManagerResponseDto,
  BusinessStructureOwnerResponseDto,
  GetBusinessStructureResponseDto,
} from "utils/swagger_react_query";

export const getPotentialCardholdersList = (
  businessOwnersStructure: GetBusinessStructureResponseDto | null,
): Array<BusinessStructureKeyManagerResponseDto | BusinessStructureOwnerResponseDto> => {
  const cardHolders = [
    ...(businessOwnersStructure?.keyManagers || []),
    ...(businessOwnersStructure?.owners || []),
    ...(businessOwnersStructure?.businessOwners
      ? businessOwnersStructure.businessOwners.map((business) => business.relatedKeyManager)
      : []),
  ].filter(
    (it): it is BusinessStructureKeyManagerResponseDto | BusinessStructureOwnerResponseDto =>
      it !== undefined,
  );

  const uniqueCardholders = Array.from(
    new Map(cardHolders.map((item) => [item.businessStructurePersonId, item])).values(),
  );

  return uniqueCardholders;
};
