import { useEffect, useState } from "react";
import { useAppSelector } from "store/hooks";
import { companyMetadataSelector, userMetadataSelector } from "store/selectors";

import { MFAFactorType } from "types/BETypes";
import { shouldFetchInternalBankAccounts } from "helpers/shared/bankAccounts";
import { isKybPending } from "helpers/shared/kyb";
import { useBankAccountLink } from "hooks";
import useAuth from "hooks/useAuth";
import { useDefaultBankAccount } from "hooks/useDefaultBankAccount";
import { useInternalBankAccounts } from "hooks/useInternalBankAccounts";
import { useKycStatusModals } from "components/CompanyBankAccountComponents/employee/KYCVerification";
import { EKycResponseType } from "components/CompanyBankAccountComponents/employee/KYCVerification/types";
import useMFASMS from "components/MultiFactorAuthorization/components/sms/useMFASMS";
import { MFAWidgetType } from "components/MultiFactorAuthorization/types";

import { SyncteraAccountDto } from "utils/swagger_react_query";

export const useConnectBankStep = () => {
  const currentUser = useAppSelector(userMetadataSelector);
  const currentCompany = useAppSelector(companyMetadataSelector);
  const { getCompanyInfo } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isCreationBusinessBankAccountModalOpen, setIsCreationBusinessBankAccountModalOpen] =
    useState<boolean>(false);
  const { showModal } = useKycStatusModals();

  const refetchDataOnDefaultBankAccountChange = async () => {
    await getCompanyInfo(currentCompany?.companyId || "", true);
    await bankAccountLink.actions.refetchBankAccountsList(false, false);
    if (shouldFetchInternalBankAccounts(currentUser, currentCompany)) {
      await internalBankAccountsData.initFetch(false);
    }
  };
  const bankAccountLink = useBankAccountLink({
    loadingCallback: setIsLoading,
    shouldFetchBankAccountsListOnMount: false,
  });
  const internalBankAccountsData = useInternalBankAccounts({
    setIsLoading: setIsLoading,
    shouldFetchDataOnMount: false,
    fetchBankCards: true,
  });
  const { companyBankAccount, bankCards } = internalBankAccountsData;

  const { defaultBankAccountId, handleSetDefaultBankAccount, determineDefaultBankAccountId } =
    useDefaultBankAccount({
      loadingCallback: setIsLoading,
      onChangeDefaultBankAccountCallback: refetchDataOnDefaultBankAccountChange,
    });

  const { factorsData, fetchData: fetchFactorsData } = useMFASMS({
    widgetType: MFAWidgetType.MISC,
    fetchDataOnMount: false,
  });

  const isMFAEnabled = !!factorsData?.some(
    (factor) => factor.type === MFAFactorType.SMS && factor.isVerified,
  );

  useEffect(() => {
    if (currentUser) {
      initFetch();
    }
  }, []);

  const initFetch = async () => {
    try {
      setIsLoading(true);
      const externalBankCards = await bankAccountLink.actions.refetchBankAccountsList(false);
      let internalBankAccountRes: SyncteraAccountDto | undefined = undefined;
      if (shouldFetchInternalBankAccounts(currentUser, currentCompany)) {
        const res = await internalBankAccountsData.initFetch();
        internalBankAccountRes = res?.bankAccountRes;
        await fetchFactorsData();
      }

      determineDefaultBankAccountId(internalBankAccountRes, externalBankCards);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleOpenSetupModal = () => {
    const _isKybPending = isKybPending(currentCompany);
    if (_isKybPending) return showModal({ type: EKycResponseType.VERIFICATION_IS_TAKING_TOO_LONG });
    setIsCreationBusinessBankAccountModalOpen(true);
  };

  return {
    metadata: {
      currentUser,
      currentCompany,
      isLoading,
      isMFAEnabled,
    },
    pageData: {
      externalBankAccounts: bankAccountLink.data.bankAccounts,
      internalBankAccount: { companyBankAccount, bankCards },
      defaultBankAccountId,
      factorsData,
    },
    actions: {
      externalBankAccounts: { ...bankAccountLink.actions },
      handleOpenSetupModal,
      handleSetDefaultBankAccount,
      initFetch,
    },
    modals: {
      isCreationBusinessBankAccountModalOpen,
      setIsCreationBusinessBankAccountModalOpen,
    },
  };
};
