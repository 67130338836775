import { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { useAppSelector } from "store/hooks";
import { userMetadataSelector } from "store/selectors";

import { PayDistributionType, SalsaOnboardingStatus } from "types/BETypes";
import { showErrorModal } from "helpers";
import { getDeferredData } from "helpers/employee/employeeRequests";
import { isKycAccepted } from "helpers/shared/kyc";
import useAuth from "hooks/useAuth";
import useCommonModals from "components/PayDistribution/useCommonModals";

import { StepIndicator } from "uikit";
import { Step } from "uikit/StepIndicator/types";

import {
  BankCardResDto,
  queryBankCardsControllerListBankCards,
  UserResponseDto,
} from "utils/swagger_react_query";

import { STEPS } from "./constants";
import BankAccountsStep from "./steps/BankAccountsStep";
import PayDistributionStep from "./steps/PayDistributionStep";
import SetupPayrollStep from "./steps/SetupPayrollStep";
import { TabsContainer, TabsContentContainer } from "./styles";

export const useEmployeeSetup = () => {
  const translationPrefix = `employee_setup_page`;
  const [isLoading, setLoading] = useState<boolean>(false);
  const [cardsData, setCardsData] = useState<BankCardResDto[] | undefined>(undefined);
  const { getCurrentUser, saveUserToStore } = useAuth();
  const currentUser = useAppSelector(userMetadataSelector);
  const shouldRequestCards =
    isKycAccepted(currentUser) &&
    !!currentUser?.defaultPayDistribution?.find(
      (item) => item.type === PayDistributionType.PARTNER_ACCOUNT,
    );

  const { showNavigationWarningModal } = useCommonModals();

  const defaultStep =
    currentUser?.salsaOnboardingStatus === SalsaOnboardingStatus.COMPLETED
      ? STEPS.BANK_ACCOUNTS
      : STEPS.SETUP_PAYROLL;

  const [currentStep, setCurrentStep] = useState<number>(defaultStep);
  const [stepsDisabled, setStepsDisabled] = useState<Record<STEPS, boolean>>({
    [STEPS.SETUP_PAYROLL]: false,
    [STEPS.BANK_ACCOUNTS]: false,
    [STEPS.PAY_DISTRIBUTION]: false,
  });

  // It was decided to hide step indicator on salsa onboarding,
  // because they have almost identical step indicator, we didn't want duplicates
  const showStepIndicator =
    currentUser?.salsaOnboardingStatus === SalsaOnboardingStatus.COMPLETED ||
    currentStep !== STEPS.SETUP_PAYROLL;

  useEffect(() => {
    if (
      currentStep === STEPS.SETUP_PAYROLL &&
      currentUser?.salsaOnboardingStatus === SalsaOnboardingStatus.COMPLETED
    ) {
      setStepsDisabled({
        ...stepsDisabled,
        [STEPS.BANK_ACCOUNTS]: false,
        [STEPS.PAY_DISTRIBUTION]: false, //TODO: I don't like that logic. We should move it to conditional constants , not states
      });
    }
  }, [currentUser?.salsaOnboardingStatus, currentStep]); //TODO: Dubious code, do we really need that logic?

  const moveToOnboardingStep = () => {
    setCurrentStep(STEPS.SETUP_PAYROLL);
  };

  const moveToBankAccountsStep = () => {
    if (!stepsDisabled[STEPS.BANK_ACCOUNTS]) {
      setCurrentStep(STEPS.BANK_ACCOUNTS);
    }
  };

  const moveToPayDistributionStep = () => {
    if (!stepsDisabled[STEPS.PAY_DISTRIBUTION]) {
      setCurrentStep(STEPS.PAY_DISTRIBUTION);
    }
  };

  const steps: Step[] = [
    {
      index: STEPS.SETUP_PAYROLL,
      label: <Trans i18nKey={`${translationPrefix}.steps.setup_payroll.title`} />,
    },
    {
      index: STEPS.BANK_ACCOUNTS,
      label: <Trans i18nKey={`${translationPrefix}.steps.bank_accounts.title`} />,
    },
    {
      index: STEPS.PAY_DISTRIBUTION,
      label: <Trans i18nKey={`${translationPrefix}.steps.pay_distribution.title`} />,
    },
  ];

  const onSalsaOnboardingComplete = async () => {
    setLoading(true);

    const request = async () => {
      const data = await getCurrentUser(undefined, undefined, false);
      return data;
    };

    const condition = (data?: UserResponseDto | null) => {
      return data?.salsaOnboardingStatus === SalsaOnboardingStatus.COMPLETED;
    };

    //Our user might not have updated status right away, we need to wait for it to become completed
    const result = await getDeferredData(request, condition, 2000);

    if (condition(result)) {
      saveUserToStore(result);
      setCurrentStep(STEPS.BANK_ACCOUNTS);
    } else {
      showErrorModal();
    }
    setLoading(false);
  };

  const handleBankAccountStepBack = () => {
    moveToOnboardingStep();
  };

  const handleBankAccountStepContinue = () => {
    moveToPayDistributionStep();
  };

  const handlePayDistributionStepBack = () => {
    moveToBankAccountsStep();
  };

  const handlePayDistributionNoAccountsConfirm = () => {
    moveToBankAccountsStep();
  };
  const handlePayDistributionBankAccountAdd = () => {
    showNavigationWarningModal(() => {
      moveToBankAccountsStep();
    });
  };

  const renderTabs = () => {
    return (
      <>
        <TabsContainer>
          {!!showStepIndicator && <StepIndicator steps={steps} currentStep={currentStep} />}
        </TabsContainer>
        <TabsContentContainer>
          {currentStep === STEPS.SETUP_PAYROLL && (
            <SetupPayrollStep
              currentUser={currentUser as UserResponseDto}
              onSalsaOnboardingComplete={onSalsaOnboardingComplete}
            />
          )}
          {currentStep === STEPS.BANK_ACCOUNTS && (
            <BankAccountsStep
              onBack={handleBankAccountStepBack}
              onContinue={handleBankAccountStepContinue}
            />
          )}
          {currentStep === STEPS.PAY_DISTRIBUTION && (
            <PayDistributionStep
              onBack={handlePayDistributionStepBack}
              onNoAccountsModalConfirm={handlePayDistributionNoAccountsConfirm}
              onBankAccountAdd={handlePayDistributionBankAccountAdd}
            />
          )}
        </TabsContentContainer>
      </>
    );
  };

  const renderContent = () => {
    return <>{renderTabs()}</>;
  };

  const requestCards = async () => {
    if (!shouldRequestCards) return;
    try {
      setLoading(true);
      const result = await queryBankCardsControllerListBankCards();
      setCardsData(result.cards);
    } catch (error: any) {
      if (error?.data?.error !== "USER_HAS_NO_PARTNER_BANK_ACCOUNT") {
        showErrorModal(error);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    requestCards();
  }, [currentUser]);

  return {
    metadata: {
      translationPrefix,
      currentUser,
      isLoading: isLoading,
    },
    pageData: {
      currentStep,
      steps,
      cardsData,
    },
    actions: {
      moveToOnboardingStep,
      moveToBankAccountsStep,
      onSalsaOnboardingComplete,
    },
    renderContent,
  };
};
