import { FC } from "react";

import { IRadioButtonProps } from "./types";
import { Label, LabelWrapper, RadioAlt, RadioInput, Wrapper } from "./styles";

const RadioButtonAlt: FC<IRadioButtonProps> = ({
  name,
  value,
  id,
  onChange,
  label,
  className,
  isDisabled,
}) => {
  const isChecked = id === value;
  return (
    <Wrapper className={className} disabled={isDisabled}>
      <LabelWrapper onChange={() => onChange(id)}>
        <RadioInput type="radio" checked={isChecked} name={name} />
        <RadioAlt className={isChecked ? "checked" : ""}>{isChecked && <div />}</RadioAlt>
        {label && <Label>{label}</Label>}
      </LabelWrapper>
    </Wrapper>
  );
};

export default RadioButtonAlt;
