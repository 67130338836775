import styled, { css } from "styled-components";

import WarningSection from "components/WarningSection";

import { LoaderContainer } from "uikit/Loader/styles";

const errorActionTextMixin = css`
  margin-top: 8px;
  display: flex;
  align-items: center;
  width: fit-content;
  color: ${({ theme }) => theme.colors.warningAlt};
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  line-height: 20px;
`;

export const Container = styled.div``;

export const StyledWarningSection = styled(WarningSection)``;

export const ESInviteButton = styled.span`
  ${errorActionTextMixin}
  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.warning};
  }

  &.disabled {
    cursor: not-allowed;
    pointer-events: none;
    color: ${({ theme }) => theme.colors.disabled};
  }

  ${LoaderContainer} {
    width: 16px;
    height: 16px;
    min-height: auto;
    min-width: auto;
    flex: none;
    margin-right: 8px;
    div {
      width: 16px;
      height: 16px;
      border-width: 2px;
      margin: 0;
    }
  }
`;

export const ESInviteSentState = styled.span`
  ${errorActionTextMixin};
  color: ${({ theme }) => theme.colors.successAlt};

  svg {
    margin-right: 8px;
    width: 16px;
    height: 16px;

    circle {
      fill: ${({ theme }) => theme.colors.successAlt};
    }
  }
`;
