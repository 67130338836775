import { FC, ReactNode, useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { ArrowIconSmall } from "assets/svg";
import { Formik, FormikProps } from "formik";
import { isEmpty } from "lodash";

import { useAsyncUserSearchInput } from "components/AsyncUserSearchInput/useAsyncUserSearchInput";
import { IBusinessOwnersStructureProps } from "components/BusinessOwnersStructure/types";
import { renderFooterButtonsSection } from "components/CompanyBankAccountComponents/components/renderElements";

import { FullSectionLoader } from "uikit";

import {
  BusinessStructureBusinessOwnersResponseDto,
  BusinessStructureCompanyResponseDto,
  BusinessStructureKeyManagerResponseDto,
  BusinessStructureOwnerResponseDto,
  GetBusinessStructureResponseDto,
  UserResponseDto,
} from "utils/swagger_react_query";

import {
  convertBusinessDataResToFormData,
  convertIndividualOwnerDataResToFormData,
  convertKeyManagerDataResToFormData,
} from "../../helpers";
import { BusinessInformationForm } from "../BusinessInformationForm";
import {
  BusinessInformationFormType,
  businessInformationFormValidationSchema,
} from "../BusinessInformationForm/validationSchema";
import { BusinessKeyManagerForm } from "../BusinessKeyManagerForm";
import {
  BusinessKeyManagerFormType,
  BusinessKeyManagerFormValidationSchema,
} from "../BusinessKeyManagerForm/validationSchema";
import { BusinessStructureEntityType } from "../BusinessStructureEntityCard/types";
import { IndividualOwnerForm } from "../IndividualOwnerForm";
import {
  IndividualOwnerFormType,
  IndividualOwnerFormValidationSchema,
} from "../IndividualOwnerForm/validationSchema";
import { SDescription, SHeader, SHeaderTextContainer, STitle } from "../styles";
import {
  BackBtnWithArrow,
  FormContainerInner,
  StyledAsyncUserSearchInput,
  StyledFormikForm,
} from "./styles";

interface IGetFormData {
  form: ReactNode;
  initialValues: any;
  validationSchema: any;
  mainButtonText: ReactNode;
}

interface IProps extends Pick<IBusinessOwnersStructureProps, "widgetType"> {
  selectedEntityType: BusinessStructureEntityType;
  isFormSubmitting?: boolean;
  entityData?:
    | BusinessStructureBusinessOwnersResponseDto
    | BusinessStructureKeyManagerResponseDto
    | BusinessStructureOwnerResponseDto
    | null;
  onBackClick: () => void;
  onSubmitEntity: (
    formikProps: FormikProps<
      IndividualOwnerFormType | BusinessKeyManagerFormType | BusinessInformationFormType
    >,
    sendAdminInvite?: boolean,
  ) => void;
  onSubmitEditedEntity?: (
    entityId: string,
    formikProps: FormikProps<
      IndividualOwnerFormType | BusinessKeyManagerFormType | BusinessInformationFormType
    >,
  ) => void;
  onOpenEntityForm?: (selectedUser: UserResponseDto | undefined) => void;
  showEntityForm?: boolean;
  businessStructure: GetBusinessStructureResponseDto;
}

export const FormSectionContainer: FC<IProps> = ({
  selectedEntityType,
  onBackClick,
  isFormSubmitting,
  onSubmitEntity,
  entityData,
  onOpenEntityForm,
  onSubmitEditedEntity,
  showEntityForm,
  businessStructure,
}) => {
  const { t } = useTranslation();
  const originTranslationPrefix = `components.business_owner_structure`;
  const translationPrefix = `${originTranslationPrefix}.entity_form_section`;
  const translationPrefixByEntity = `${translationPrefix}.${selectedEntityType}`;
  const asyncUserSearchInputProps = useAsyncUserSearchInput({
    businessStructure,
    selectedEntityType,
  });
  const [svoc, setSvoc] = useState<boolean>(false);

  useEffect(() => {
    if (svoc) setSvoc(false);
  }, [showEntityForm]);

  const shouldDisableSubmitBtn = useMemo(() => {
    if (!showEntityForm) {
      return (
        asyncUserSearchInputProps.metadata.isLoading ||
        isEmpty(asyncUserSearchInputProps.data.selectedUser)
      );
    }
  }, [
    showEntityForm,
    asyncUserSearchInputProps.data.selectedUser,
    asyncUserSearchInputProps.metadata.isLoading,
  ]);

  const getFormParams = (): IGetFormData | undefined => {
    switch (selectedEntityType) {
      case BusinessStructureEntityType.OWNER:
        return {
          initialValues: convertIndividualOwnerDataResToFormData(
            entityData as BusinessStructureOwnerResponseDto,
          ),
          validationSchema: IndividualOwnerFormValidationSchema,
          form: <IndividualOwnerForm key={BusinessStructureEntityType.OWNER} />,
          mainButtonText: <Trans i18nKey={`buttons.continue`} />,
        };
      case BusinessStructureEntityType.BUSINESS:
        return {
          initialValues: convertBusinessDataResToFormData(
            entityData as BusinessStructureBusinessOwnersResponseDto,
          ),
          validationSchema: businessInformationFormValidationSchema,
          form: <BusinessInformationForm key={BusinessStructureEntityType.BUSINESS} />,
          mainButtonText: <Trans i18nKey={`buttons.continue`} />,
        };
      case BusinessStructureEntityType.KEY_MANAGER:
        return {
          initialValues: convertKeyManagerDataResToFormData(
            entityData as BusinessStructureKeyManagerResponseDto,
          ),
          validationSchema: BusinessKeyManagerFormValidationSchema,
          form: <BusinessKeyManagerForm key={BusinessStructureEntityType.KEY_MANAGER} />,
          mainButtonText: <Trans i18nKey={`buttons.continue`} />,
        };
      default:
        return undefined;
    }
  };
  const _formParams = getFormParams();

  const handleBackClick = () => {
    if (showEntityForm) {
      onBackClick();
    } else {
      onBackClick();
      asyncUserSearchInputProps.metadata.cleanUpStates();
    }
  };

  const entityId = () => {
    switch (selectedEntityType) {
      case BusinessStructureEntityType.BUSINESS:
        return (entityData as BusinessStructureCompanyResponseDto)?.businessStructureBusinessId;
      case BusinessStructureEntityType.OWNER:
        return (entityData as BusinessStructureOwnerResponseDto)?.businessStructurePersonId;
      case BusinessStructureEntityType.KEY_MANAGER:
        return (entityData as BusinessStructureKeyManagerResponseDto)?.businessStructurePersonId;
      default:
        return "";
    }
  };

  return (
    <>
      <BackBtnWithArrow
        onClick={handleBackClick}
        data-testid={"form-section-container-back-arrow-button"}
      >
        <ArrowIconSmall />
        <Trans i18nKey={`${translationPrefix}.back_button_text`} />
      </BackBtnWithArrow>

      <SHeader>
        <SHeaderTextContainer>
          <STitle>
            <Trans i18nKey={`${translationPrefixByEntity}.title`} />
          </STitle>
          {selectedEntityType !== BusinessStructureEntityType.KEY_MANAGER && (
            <SDescription>
              <Trans i18nKey={`${translationPrefixByEntity}.description`} />
            </SDescription>
          )}
        </SHeaderTextContainer>
      </SHeader>

      {showEntityForm ? (
        <Formik
          initialValues={_formParams?.initialValues}
          validationSchema={_formParams?.validationSchema}
          validateOnChange={svoc}
          onSubmit={() => {}}
        >
          {(formikProps) => (
            <StyledFormikForm
              onSubmit={(e) => {
                e.preventDefault();
                setSvoc(true);
                const _entityId = entityId();
                if (_entityId) {
                  onSubmitEditedEntity?.(_entityId, formikProps);
                  return;
                }
                onSubmitEntity(formikProps, asyncUserSearchInputProps.data.sendAdminInvite);
              }}
            >
              {isFormSubmitting && <FullSectionLoader />}
              <FormContainerInner>{_formParams?.form}</FormContainerInner>
              <>
                {renderFooterButtonsSection({
                  mainButton: {
                    text: <Trans i18nKey={`buttons.continue`} />,
                    disabled: shouldDisableSubmitBtn,
                    type: "submit",
                    dataTestId: "cbacm-business-structure-submit-button",
                  },
                  secondaryButton: {
                    text: <Trans i18nKey="buttons.cancel" />,
                    onClick: onBackClick,
                    type: "button",
                    dataTestId: "cbacm-business-structure-form-cancel-button",
                  },
                })}
              </>
            </StyledFormikForm>
          )}
        </Formik>
      ) : (
        <>
          <StyledAsyncUserSearchInput
            name="email"
            label={t(`${translationPrefix}.search_input.input_title`)}
            emptyDataMessage={t(`${translationPrefix}.search_input.empty_message`)}
            dataTestid="async-user-search-input"
            showSelectedOptionBelow
            searchValue={asyncUserSearchInputProps.data.searchValue}
            searchResult={asyncUserSearchInputProps.data.searchResult}
            isLoading={asyncUserSearchInputProps.metadata.isLoading}
            onSearch={asyncUserSearchInputProps.actions.handleSearch}
            onSelect={asyncUserSearchInputProps.actions.handleSelectOption}
            selectedOption={asyncUserSearchInputProps.data.selectedUser}
            warning={asyncUserSearchInputProps.metadata.warningMessage || ""}
            isSendInviteCheckboxChecked={asyncUserSearchInputProps.data.sendAdminInvite}
            onSendInviteCheckboxChange={
              asyncUserSearchInputProps.actions.handleSendAdminInviteCheckboxChange
            }
          />

          <>
            {renderFooterButtonsSection({
              mainButton: {
                text: <Trans i18nKey={`buttons.continue`} />,
                onClick: (e) => {
                  e.preventDefault();
                  onOpenEntityForm?.(
                    asyncUserSearchInputProps.data.selectedUser as UserResponseDto,
                  );
                },
                disabled: shouldDisableSubmitBtn,
                type: "button",
                dataTestId: "cbacm-business-structure-submit-button",
              },
              secondaryButton: {
                text: <Trans i18nKey="buttons.cancel" />,
                onClick: onBackClick,
                type: "button",
                dataTestId: "cbacm-business-structure-form-cancel-button",
              },
            })}
          </>
        </>
      )}
    </>
  );
};
