import { CompanyEntityType } from "types/BETypes";
import { companyTypeSelectOptions } from "constants/shared/companyTypes";

export const getCompanyTypeSelectOption = (
  companyType: CompanyEntityType,
): { label: string; value: CompanyEntityType } => {
  return companyTypeSelectOptions.find((option) => option.value === companyType) as {
    label: string;
    value: CompanyEntityType;
  };
};
