import { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { isArray, isEmpty, isUndefined } from "lodash";
import { useAppSelector } from "store/hooks";
import { companyMetadataSelector, userMetadataSelector } from "store/selectors";

import { AppEvents } from "services/events";
import { BankingType, UserRole } from "types/BETypes";
import { showErrorModal } from "helpers";
import { shouldFetchInternalBankAccounts } from "helpers/shared/bankAccounts";
import { getErrorMessageFromResponse } from "helpers/shared/errors";
import { isKycAccepted, isKycPending, isKycRejected } from "helpers/shared/kyc";
import useAuth from "hooks/useAuth";
import { useBusinessOwnersStructure } from "components/BusinessOwnersStructure";
import {
  getCombinedUserDataWithFailedKycData,
  getFailedKycUserDataFromLocalStorage,
  removeFailedKycUserDataFromLocalStorage,
} from "components/CompanyBankAccountComponents/employee/KYCVerification/helpers";
import useMFASMS from "components/MultiFactorAuthorization/components/sms/useMFASMS";
import { MFAWidgetType } from "components/MultiFactorAuthorization/types";

import { EButtonsFlex, EModalTypes } from "uikit/Modal";

import {
  BankCardResDto,
  GetBusinessStructureResponseDto,
  queryBankCardsControllerListBankCards,
} from "utils/swagger_react_query";

import { EKycResponseType } from "../employee/KYCVerification/types";
import {
  COMPANY_BANK_ACCOUNT_CREATION_ADMIN_STEPS,
  COMPANY_BANK_ACCOUNT_CREATION_EMPLOYEE_STEPS,
} from "./constants";
import { useFormController } from "./hooks/useFormController";
import { useStepController } from "./hooks/useStepController";
import { IUseCompanyBankAccountCreationModal } from "./types";
export const useCompanyBankAccountCreationModal = ({
  isOpen,
  setIsOpen,
  onFinished,
  onClose,
  refetchPageContentCallback,
  isShippingStepOnly,
  data,
}: IUseCompanyBankAccountCreationModal) => {
  const translationPrefix = `components.company_bank_account_components`;
  const { getCurrentUser } = useAuth();
  // COMMON
  const [isPageLoading, setIsPageLoading] = useState<boolean>(true);
  const currentCompany = useAppSelector(companyMetadataSelector);
  const currentUserDataFromStore = useAppSelector(userMetadataSelector);
  const currentUser = _getCurrentUserByKycStatus();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const [showPromoSection, setShowPromoSection] = useState<boolean>(true);
  const [isBankAccountSetupCompletedSectionVisible, setIsBankAccountSetupCompletedSectionVisible] =
    useState<boolean>(false);
  const [shouldShowKybResponseTypeSectionOnMount, setShouldShowKybResponseTypeSectionOnMount] =
    useState<boolean>(true);

  const [bankCardsList, setBankCardsList] = useState<BankCardResDto[]>([]);
  const kycIsAccepted = isKycAccepted(currentUser);
  const kycIsPending = isKycPending(currentUser);
  const kycIsRejected = isKycRejected(currentUser);

  const { fetchData: fetchFactorsData, factorsData: factorsData } = useMFASMS({
    fetchDataOnMount: false,
    widgetType: MFAWidgetType.MISC,
  });

  const { currentStep, setCurrentStep, determineInitialStep } = useStepController({
    isShippingStepOnly,
    isLoading: isPageLoading,
  });

  const {
    fetchData: fetchBusinessStructureData,
    pagedData: { businessOwnersStructure },
    actions: { handleSetBusinessStructure },
  } = useBusinessOwnersStructure({
    fetchDataOnMount: false,
  });

  //FORM CONTROLLERS
  const { kyc, earlyPay, cardShippingDetails, mfa, cleanUpFormsData, businessInfo, kyb } =
    useFormController({
      factors: factorsData || [],
      fetchCompanyBankCards: fetchCompanyBankCards,
      setErrorMessage: setErrorMessage,
      setCurrentStep,
      isShippingStepOnly,
      showSuccessSetupSection: () => setIsBankAccountSetupCompletedSectionVisible(true),
    });

  useEffect(() => {
    fetchMainData(true);

    return () => {
      removeFailedKycUserDataFromLocalStorage();
    };
  }, [isOpen]);

  //Common
  const fetchAdminData = async (companyCards: BankCardResDto[]) => {
    const businessStructureRes = await fetchBusinessStructureData();
    const shouldShowPromoSection = isEmpty(businessStructureRes?.business);
    setShowPromoSection(shouldShowPromoSection);
    const _initialStep = determineInitialStep({
      bankCards: companyCards,
      businessOwnershipStructure: businessStructureRes,
    });

    if (_initialStep !== currentStep) {
      setCurrentStep(_initialStep);
    }
  };
  const fetchEmployeeData = async (companyCards: BankCardResDto[]) => {
    const shouldShowPromoSection = !currentUser?.kycStatus;
    setShowPromoSection(shouldShowPromoSection);
    const _initialStep = determineInitialStep({
      bankCards: companyCards,
    });
    if (_initialStep !== currentStep) {
      setCurrentStep(_initialStep);
    }
  };

  const fetchMainData = async (isInit?: boolean) => {
    const { bankCardsList } = data || {};
    try {
      setIsPageLoading(true);
      let companyCardResponse: BankCardResDto[] = bankCardsList || [];
      const shouldFetchUserData = !isInit;
      // Fetch company bank cards only if KYC or KYB is accepted, or if it's the first time the modal is opened and internal state is undefined, or if it's a re-fetch
      const shouldFetchCompanyBankCards =
        shouldFetchInternalBankAccounts(currentUser, currentCompany) &&
        ((isInit && isUndefined(bankCardsList)) || !isInit);

      if (shouldFetchUserData) await getCurrentUser();
      if (shouldFetchCompanyBankCards) {
        const _response = await fetchCompanyBankCards();
        if (isArray(_response)) {
          companyCardResponse = _response;
        }
      } else {
        setBankCardsList(companyCardResponse);
      }

      const factorsRes = await fetchFactorsData();
      const hasVerifiedFactor = !!factorsRes?.some((factor) => factor.isVerified);
      if (hasVerifiedFactor) mfa.setIsMfaFinished(true);

      if (currentUser?.lastActiveRole === UserRole.EMPLOYEE) {
        await fetchEmployeeData(companyCardResponse);
      } else {
        await fetchAdminData(companyCardResponse);
      }
    } catch (error) {
      handleCloseModal();
      showErrorModal(error);
    } finally {
      setIsPageLoading(false);
    }
  };

  async function fetchCompanyBankCards(
    save: boolean = true,
    handleError?: boolean | undefined,
  ): Promise<BankCardResDto[] | unknown> {
    try {
      const requestBankingTypeByRole =
        currentUser?.lastActiveRole === UserRole.EMPLOYEE
          ? BankingType.PERSONAL
          : BankingType.BUSINESS;
      const companyBankCardsResponse = await queryBankCardsControllerListBankCards({
        bankingType: requestBankingTypeByRole,
      });
      if (save) setBankCardsList(companyBankCardsResponse?.cards || []);
      return companyBankCardsResponse?.cards || [];
    } catch (error: any) {
      if (handleError) {
        setErrorMessage(getErrorMessageFromResponse(error));
        return;
      }
      throw error;
    }
  }

  function _getCurrentUserByKycStatus() {
    const _isKycRejected = isKycRejected(currentUserDataFromStore);
    if (_isKycRejected) {
      const userDataWithFailedKyc = getCombinedUserDataWithFailedKycData(
        currentUserDataFromStore,
        getFailedKycUserDataFromLocalStorage(),
      );
      return userDataWithFailedKyc;
    }

    return currentUserDataFromStore;
  }

  const showBackClickWarningModal = () => {
    const _prefix = `${translationPrefix}.shared.close_form_confirmation`;
    const message =
      currentStep === COMPANY_BANK_ACCOUNT_CREATION_EMPLOYEE_STEPS.SHIPPING_DETAILS
        ? "shipping"
        : "common";

    AppEvents.emit("SetGlobalModal", {
      className: "pay-distribution-redirection-warning-modal",
      isOpen: true,
      type: EModalTypes.WARNING,
      title: <Trans i18nKey={`${_prefix}.title`} />,
      message: <Trans i18nKey={`${_prefix}.${message}`} />,
      mainButton: {
        text: <Trans i18nKey={`${_prefix}.continue_btn`} />,
        onClick: () => {
          setIsOpen(true);
        },
        autoWidth: true,
      },
      secondaryButton: {
        text: <Trans i18nKey={`common.modal.cancel`} />,
        onClick: () => {
          handleCloseModal();
        },
        autoWidth: true,
      },
      buttonsFlex: EButtonsFlex.ROW_REVERSE,
    });
  };

  const handleBackBtnClick = () => {
    if (mfa.isFinished) {
      handleCloseModal();
      return;
    }

    handleCloseModal(false);
    showBackClickWarningModal();
  };

  const handleCloseModal = (cleanUpData: boolean = true) => {
    if (currentUser?.lastActiveRole === UserRole.EMPLOYEE)
      currentStep as COMPANY_BANK_ACCOUNT_CREATION_EMPLOYEE_STEPS;
    const shouldRefetchOnCloseEmployee: Record<
      COMPANY_BANK_ACCOUNT_CREATION_EMPLOYEE_STEPS,
      boolean
    > = {
      [COMPANY_BANK_ACCOUNT_CREATION_EMPLOYEE_STEPS.KYC_VERIFICATION]: !!currentUser?.kycStatus,
      [COMPANY_BANK_ACCOUNT_CREATION_EMPLOYEE_STEPS.EARLY_PAY]: kyc.isFinished,
      [COMPANY_BANK_ACCOUNT_CREATION_EMPLOYEE_STEPS.SHIPPING_DETAILS]: earlyPay.isFinished,
      [COMPANY_BANK_ACCOUNT_CREATION_EMPLOYEE_STEPS.MFA_CONFIGURATION]:
        cardShippingDetails.isFinished ||
        isBankAccountSetupCompletedSectionVisible ||
        mfa.isFinished,
    };

    const shouldRefetchOnCloseAdmin: Record<COMPANY_BANK_ACCOUNT_CREATION_ADMIN_STEPS, boolean> = {
      [COMPANY_BANK_ACCOUNT_CREATION_ADMIN_STEPS.BUSINESS_INFO]: businessInfo.isFinished,
      [COMPANY_BANK_ACCOUNT_CREATION_ADMIN_STEPS.COMPANY_STRUCTURE]: businessInfo.isFinished,
      [COMPANY_BANK_ACCOUNT_CREATION_ADMIN_STEPS.SHIPPING_DETAILS]: kyb.isKybFinished,
      [COMPANY_BANK_ACCOUNT_CREATION_ADMIN_STEPS.MFA_CONFIGURATION]: cardShippingDetails.isFinished,
    };
    if (cleanUpData) {
      if (currentStep && shouldRefetchOnCloseEmployee[currentStep]) {
        refetchPageContentCallback?.();
      }
      if (currentStep && shouldRefetchOnCloseAdmin[currentStep]) {
        refetchPageContentCallback?.();
      }

      cleanUpFormsData();
      setCurrentStep(null);
      onClose?.();
      setErrorMessage(undefined);
      setIsBankAccountSetupCompletedSectionVisible(false);
      setShouldShowKybResponseTypeSectionOnMount(true);
    }

    setIsOpen(false);
  };

  const handleMfaPrimaryBtnClick = () => {
    setIsBankAccountSetupCompletedSectionVisible(true);
  };
  const handleSkipMfaStep = () => {
    setIsBankAccountSetupCompletedSectionVisible(true);
  };

  const handleShowPromoSection = () => {
    setShowPromoSection((prev) => !prev);
  };

  const handlePromoSectionBackBtnClick = () => {
    handleCloseModal(false);
  };

  const handleFinishedSetupSectionBtnClick = () => {
    handleCloseModal();
    onFinished?.();
  };

  const handleKybStatusSectionPrimaryBtnClick = (
    responseType: EKycResponseType,
    businessStructure: GetBusinessStructureResponseDto,
  ) => {
    //KYB
    if (responseType === EKycResponseType.VERIFICATION_IS_TAKING_TOO_LONG) {
      handleCloseModal();
    } else if (responseType === EKycResponseType.SUCCESS) {
      handleSetBusinessStructure(businessStructure);
      setCurrentStep(COMPANY_BANK_ACCOUNT_CREATION_ADMIN_STEPS.SHIPPING_DETAILS);
    } else if (responseType === EKycResponseType.ERROR) {
      setShouldShowKybResponseTypeSectionOnMount(false);
      setCurrentStep(COMPANY_BANK_ACCOUNT_CREATION_ADMIN_STEPS.BUSINESS_INFO);
    } else if (responseType === EKycResponseType.ERROR_BANK_ACCOUNT_CREATED) {
      handleCloseModal();
    }
  };

  const handleKycStatusSectionPrimaryBtnClick = (responseType: EKycResponseType) => {
    //KYC
    if (responseType === EKycResponseType.VERIFICATION_IS_TAKING_TOO_LONG) {
      handleCloseModal();
    } else if (responseType === EKycResponseType.SUCCESS) {
      setCurrentStep(COMPANY_BANK_ACCOUNT_CREATION_EMPLOYEE_STEPS.EARLY_PAY);
    }
  };

  return {
    metadata: {
      currentUser,
      isPageLoading,
      setErrorMessage,
      step: {
        currentStep,
        setCurrentStep,
      },
      kycStatusDetails: {
        isKycAccepted: kycIsAccepted,
        isKycPending: kycIsPending,
        isKycRejected: kycIsRejected,
      },
    },
    pageData: {
      bankCardsList,
      isBankAccountSetupCompletedSectionVisible,
      errorMessage,
      promoSection: {
        showPromoSection,
        handleShowPromoSection,
        handlePromoSectionBackBtnClick,
      },
      cardShippingDetails: {
        ...cardShippingDetails,
      },
      kyc: {
        ...kyc,
        handleKycStatusSectionPrimaryBtnClick,
      },
      earlyPay: {
        ...earlyPay,
      },
      mfa: {
        isFinished: mfa.isFinished,
        onSuccessSubmit: mfa.onSuccessSubmit,
        onPrimaryBtnClick: handleMfaPrimaryBtnClick,
        onSkip: handleSkipMfaStep,
      },
      businessInfo: {
        ...businessInfo,
      },
      kyb: {
        ...kyb,
        businessOwnersStructure,
        handleKybStatusSectionPrimaryBtnClick,
        shouldShowKybResponseTypeSectionOnMount,
      },
    },
    actions: {
      handleBackBtnClick,
      handleFinishedSetupSectionBtnClick,
      fetchBusinessStructureData,
    },
  };
};
