import { useEffect, useState } from "react";
import { useAppSelector } from "store/hooks";
import { userMetadataSelector } from "store/selectors";

import { BankingType } from "types/BETypes";
import { hasAdminRights } from "permissions/helpers/shared";
import { showErrorModal } from "helpers";

import {
  BankCardResDto,
  queryBankCardsControllerGetSyncteraAccount,
  queryBankCardsControllerListBankCards,
  SyncteraAccountDto,
} from "utils/swagger_react_query";

export interface IUseCompanyBankAccountsParams {
  setIsLoading: (isLoading: boolean) => void;
  shouldFetchDataOnMount?: boolean;
  fetchBankCards?: boolean;
}

export const useInternalBankAccounts = ({
  setIsLoading,
  shouldFetchDataOnMount = true,
  fetchBankCards = true,
}: IUseCompanyBankAccountsParams) => {
  const currentUser = useAppSelector(userMetadataSelector);
  const [companyBankAccount, setCompanyBankAccount] = useState<SyncteraAccountDto>();
  const [bankCards, setBankCards] = useState<BankCardResDto[]>([]);
  const userIsAdmin = hasAdminRights(currentUser);
  const requestsBankingType = userIsAdmin ? BankingType.BUSINESS : BankingType.PERSONAL;

  useEffect(() => {
    if (shouldFetchDataOnMount) {
      initFetch(fetchBankCards);
    }
  }, []);

  const initFetch = async (fetchBankCards: boolean = true) => {
    try {
      setIsLoading(true);
      const result: {
        bankAccountRes?: SyncteraAccountDto;
        bankCardsRes?: BankCardResDto[];
      } = {};

      const bankAccountRes = await queryBankCardsControllerGetSyncteraAccount({
        bankingType: requestsBankingType,
      });
      setCompanyBankAccount(bankAccountRes);
      result.bankAccountRes = bankAccountRes;

      if (fetchBankCards) {
        const bankCardsRes = await queryBankCardsControllerListBankCards({
          bankingType: requestsBankingType,
        });
        setBankCards(bankCardsRes.cards);
        result.bankCardsRes = bankCardsRes.cards;
      }

      return result;
    } catch (error) {
      showErrorModal(error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    companyBankAccount,
    bankCards,
    initFetch,
  };
};
