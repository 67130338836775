import { BankIconPlaceholder, CreditCard, LogoFull, ModalDelete } from "assets/svg";
import styled, { css } from "styled-components";

import { BankAccountVerifyStatus } from "types/BankAccountVerifyStatus";

import { Body2Mixin, LinkTextMixin, PrimaryButton, RoundBorderedContainer } from "uikit";

const logoSize: string = "46px";

const LogoMixin = css`
  width: ${logoSize};
  height: ${logoSize};
  border-radius: 6px;
  margin-right: 6px;
`;

export const Container = styled(RoundBorderedContainer)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: ${({ theme }) => theme.colors.contentBg};
`;

export const RadioBtnColumn = styled.div`
  margin-right: 24px;
  width: 20px;
`;

export const InfoSection = styled.div`
  display: grid;
  grid-template-columns: 50px repeat(auto-fit, minmax(100px, 1fr));
  grid-template-rows: auto;
  gap: 0 18px;
  align-items: center;
  width: 100%;

  &.large {
    div {
      width: 250px;
    }
  }
`;

export const BankLogo = styled.img`
  ${LogoMixin}
`;

export const PaidBankLogo = styled(LogoFull)`
  ${LogoMixin}
`;

export const StyledBankIconPlaceholder = styled(BankIconPlaceholder)`
  ${LogoMixin}
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 138px;
`;

export const Label = styled.span`
  font-size: ${({ theme }) => theme.size.caption};
  color: ${({ theme }) => theme.colors.textAlt};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  line-height: 15.6px;
  margin-bottom: 6px;
`;

export const Value = styled.span`
  ${Body2Mixin};
  line-height: 18.2px;
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
`;

export const PendingVerifyLink = styled.span`
  ${LinkTextMixin};
  font-style: italic;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.accentSecondary} !important;
`;

export const VerificationStatusValue = styled(Value)`
  &.${BankAccountVerifyStatus.AUTOMATICALLY_VERIFIED},
    &.${BankAccountVerifyStatus.MANUALLY_VERIFIED},
    &.${BankAccountVerifyStatus.INSTANTLY_VERIFIED} {
    color: ${({ theme }) => theme.colors.plaidVerificationStatusVerified};
  }

  &.${BankAccountVerifyStatus.VERIFICATION_EXPIRED},
    &.${BankAccountVerifyStatus.VERIFICATION_FAILED} {
    color: ${({ theme }) => theme.colors.plaidVerificationStatusFailed};
  }

  &.${BankAccountVerifyStatus.PENDING_AUTOMATIC_VERIFICATION},
    &.${BankAccountVerifyStatus.PENDING_MANUAL_VERIFICATION} {
    color: ${({ theme }) => theme.colors.plaidVerificationStatusPending};
  }
`;

export const ActionsColumn = styled(Column)`
  flex-direction: row;
  justify-content: flex-end;
  gap: 16px;
  width: 200px;

  &.large {
    width: 300px;
  }
`;

export const VerifyBtn = styled(PrimaryButton)`
  width: auto;
`;

export const BankInUseIcon = styled(CreditCard)`
  margin-right: 8px;
  width: 16px;
  height: 16px;
  path {
    stroke: ${({ theme }) => theme.colors.accentMain};
  }
`;

export const BankInUseLink = styled(Value)`
  ${LinkTextMixin};
  display: flex;
  align-items: center;
`;

export const RemoveBankAccountBtn = styled.div`
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 32px;
  background-color: ${({ theme }) => theme.colors.dangerAlt3};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RemoveBankAccountIcon = styled(ModalDelete)`
  path {
    stroke: ${({ theme }) => theme.colors.danger};
  }
`;
