import styled from "styled-components";

import { breakpoint } from "helpers";

import { EmphasizedContentBadge } from "uikit/Containers/Containers";

export const OptionsSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0;
`;

export const CountText = styled(EmphasizedContentBadge)`
  svg {
    margin-right: 8px;
    width: 16px;
    height: 16px;

    path {
      stroke: ${({ theme }) => theme.colors.textAlt4};
    }
  }

  ${breakpoint("xs", "lg")`
    width: 100%;
  `}
`;
