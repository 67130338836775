import * as yup from "yup";

import {
  addressValidationRulesBase,
  businessInformationValidationRulesBase,
} from "constants/shared/validationRules";

export const BusinessAccountCompanyInfoValidationSchema = yup.object({
  ...businessInformationValidationRulesBase,
  address: addressValidationRulesBase.address,
  city: addressValidationRulesBase.city,
  state: addressValidationRulesBase.state,
  zip: addressValidationRulesBase.zip,
  suite: addressValidationRulesBase.suite,
});

export type BusinessAccountCompanyInfoFormType = yup.InferType<
  typeof BusinessAccountCompanyInfoValidationSchema
>;
