import { EStates, EStatesShort, StateSelectOptions } from "constants/shared";

import { UserResponseDto } from "utils/swagger_react_query";

export const getStateSelectOption = (
  state?: UserResponseDto["state"],
): { label: EStates; value: EStatesShort } =>
  StateSelectOptions.find((_state) => _state.value === state) as {
    label: EStates;
    value: EStatesShort;
  };
