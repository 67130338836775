import { hasAdminRights } from "permissions/helpers/shared";

import {
  BankCardResDto,
  GetCompanyByIdResponseDto,
  MfaFactorDto,
  UserProfileResponseDto,
} from "utils/swagger_react_query";

import { isAdminSetupComplete } from "./adminSetup";
import { isEmployeeSetupComplete } from "./employeeSetup";

interface IСheckSetupCompletion {
  currentUser: UserProfileResponseDto | null | undefined;
  currentCompany: GetCompanyByIdResponseDto | null;
  internalBankCards: BankCardResDto[];
  factors: MfaFactorDto[];
}

export const checkSetupCompletion = ({
  currentUser,
  currentCompany,
  internalBankCards,
  factors,
}: IСheckSetupCompletion) => {
  if (hasAdminRights(currentUser)) {
    return isAdminSetupComplete(currentCompany, internalBankCards, factors);
  }

  return isEmployeeSetupComplete(currentUser, internalBankCards, factors);
};
