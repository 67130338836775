import { useAppSelector } from "store/hooks";
import { companyMetadataSelector, userMetadataSelector } from "store/selectors";

import { UserRole } from "types/BETypes";
import { hasAdminRights, hasEmployeeRights } from "permissions/helpers/shared";
import { isAdminOnboarded, isWorkerOnboarded } from "helpers";
import { hasAdvancedAdminBankingRights } from "pages/Banking/shared/permissions";

import routes from "./routes";
import { TRouteCondition, TRouteDisableCondition, TRouteResult } from "./types";

//TODO: Remake block conditions
//It'll be better to use conditions in which routes are allowed to be visited, not the other way around

export const getEmployeePagesBlockCondition: TRouteDisableCondition = (user) => {
  return hasEmployeeRights(user) && !isWorkerOnboarded(user);
};

export const getAdminPagesBlockCondition: TRouteDisableCondition = (user, company) => {
  return hasAdminRights(user) && !isAdminOnboarded(company);
};

export const getSharedPagesBlockCondition: TRouteDisableCondition = (user, company) => {
  return getEmployeePagesBlockCondition(user) || getAdminPagesBlockCondition(user, company);
};

//Banking
export const getAdminBankingBlockCondition: TRouteDisableCondition = (user, company) => {
  return !hasAdminRights(user) || !isAdminOnboarded(company);
};

export const getEmployeeBankingBlockCondition: TRouteDisableCondition = (user) => {
  return !hasEmployeeRights(user) || !isWorkerOnboarded(user);
};

export const getBankingBlockCondition: TRouteDisableCondition = (user, company) => {
  if (hasAdminRights(user)) {
    return getAdminBankingBlockCondition(user, company);
  } else if (hasEmployeeRights(user)) {
    return getEmployeeBankingBlockCondition(user, company);
  }

  return true;
};

export const getBankingAdvancedBlockCondition: TRouteDisableCondition = (user, company) => {
  return (
    getBankingBlockCondition(user, company) ||
    (hasAdminRights(user) && !hasAdvancedAdminBankingRights(user))
  );
};

//Route helpers
export const useRouteHelpers = () => {
  const currentUser = useAppSelector(userMetadataSelector);
  const currentCompany = useAppSelector(companyMetadataSelector);

  const _getEmployeePagesBlockCondition: TRouteDisableCondition = (
    user = currentUser,
    company = currentCompany,
  ) => {
    return getEmployeePagesBlockCondition(user, company);
  };

  const _getAdminPagesBlockCondition: TRouteDisableCondition = (
    user = currentUser,
    company = currentCompany,
  ) => {
    return getAdminPagesBlockCondition(user, company);
  };

  const shouldShowEmployeeOnboardingPage: TRouteCondition = (user, company) => {
    return !isWorkerOnboarded(user);
  };

  const shouldShowAdminOnboardingPage: TRouteCondition = (user, company) => {
    return !!company?.showSuccessfulOnboarding || !company?.isOnboardingComplete;
  };

  const getDefaultEmployeeRoute: TRouteResult = (user = currentUser, company = currentCompany) => {
    if (!user || !company) return routes.ROOT;
    return shouldShowEmployeeOnboardingPage(user, company)
      ? routes.EMPLOYEE_SETUP
      : routes.EMPLOYEE_PAY;
  };

  const getDefaultAdminRoute: TRouteResult = (user = currentUser, company = currentCompany) => {
    if (!user || !company) return routes.ROOT;
    return shouldShowAdminOnboardingPage(user, company) ? routes.ADMIN_SETUP : routes.ADMIN_PAY;
  };

  const getDefaultRoute: TRouteResult = (user = currentUser, company = currentCompany) => {
    if (!user) return routes.ROOT;
    return user?.lastActiveRole === UserRole.EMPLOYEE
      ? getDefaultEmployeeRoute(user, company)
      : getDefaultAdminRoute(user, company);
  };

  return {
    getEmployeePagesBlockCondition: _getEmployeePagesBlockCondition,
    getAdminPagesBlockCondition: _getAdminPagesBlockCondition,
    getDefaultEmployeeRoute,
    getDefaultAdminRoute,
    getDefaultRoute,
    isAdminOnboarded,
    isWorkerOnboarded,
    shouldShowEmployeeOnboardingPage,
    shouldShowAdminOnboardingPage,
  };
};
