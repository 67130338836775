import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router";
import { FormikProps } from "formik";
import { isEmpty } from "lodash";
import routes from "routes/routes";
import { useAppSelector } from "store/hooks";
import { userMetadataSelector } from "store/selectors";

import { AppEvents } from "services/events";
import { BankingType, ErrorConst } from "types/BETypes";
import {
  CardForm,
  CardReissueReason,
  CardStatusReason,
  CardStatusToUpdate,
} from "types/CardShipping";
import { IMarqetaWindow } from "types/Marqeta/index";
import { hasAdminRights, hasEmployeeRights } from "permissions/helpers/shared";
import { showErrorModal } from "helpers";
import { getSortedAndFilteredPersonalBankCards } from "helpers/bankCards";
import { useScript } from "hooks/useScript";
import { CompanyCardShippingDetailsFormType } from "components/CompanyBankAccountComponents/CompanyCardShippingDetails";

import { EModalTypes } from "uikit/Modal";

import {
  BankCardResDto,
  IssueBankCardReqDto,
  mutationBankCardsControllerGetCardClientToken,
  mutationBankCardsControllerIssueBankCard,
  mutationBankCardsControllerUpdateCard,
  queryBankCardsControllerGetBankCard,
  queryBankCardsControllerGetCardWidgetUrl,
  queryBankCardsControllerListBankCards,
  UserResponseDto,
} from "utils/swagger_react_query";

import { hasAdvancedAdminBankingRights } from "../permissions";
import { CARD_TABS, getMarqetaConfig, PinModalType } from "./constants";
import {
  convertCardDetailsToUserResponseDto,
  reissueCardFormValuesToRequestPayload,
} from "./helpers";

interface IEmployeeCardDetailsPathParams {
  selectedTab?: CARD_TABS;
}

export const useCardsDetailsPage = () => {
  const { t } = useTranslation();
  const translationPrefix = `banking_pages.shared.cards_details_page`;
  const navigate = useNavigate();
  const urlParams = useParams();
  const { id: cardId } = urlParams;

  const currentUser = useAppSelector(userMetadataSelector);

  //User Permissions
  const userIsAdmin = hasAdminRights(currentUser);
  const userIsEmployee = hasEmployeeRights(currentUser);
  const userIsAdvancedAdmin = hasAdvancedAdminBankingRights(currentUser);

  //Banking type for requests
  const requestsBankingType = userIsAdmin ? BankingType.BUSINESS : BankingType.PERSONAL;

  useScript((process.env.REACT_APP_MARQETA_JS_URL as string) || "", "marqeta");
  const navigationParams = useLocation().state as IEmployeeCardDetailsPathParams;

  const [isLoading, setIsLoading] = useState(true);

  const [bankCards, setBankCards] = useState<BankCardResDto[] | undefined>(undefined);
  const [cardOwner, setCardOwner] = useState<Partial<UserResponseDto> | undefined>(
    userIsEmployee ? currentUser || undefined : undefined,
  );
  const [targetBankCardId, setTargetBankCardId] = useState<string>("");
  const [isCardRevealed, setIsCardRevealed] = useState<string[]>([]);
  const [isCardViewLoading, setIsCardViewLoading] = useState<string[]>([]);

  const defaultTab =
    !cardId && navigationParams?.selectedTab
      ? CARD_TABS[navigationParams?.selectedTab] || CARD_TABS.PHYSICAL
      : CARD_TABS.PHYSICAL;

  const [currentTab, setCurrentTab] = useState<CARD_TABS | undefined>(
    cardId ? undefined : (defaultTab as number),
  );

  const [isSetPinModalOpen, setIsSetPinModalOpen] = useState<boolean>(false);
  const [pinModalType, setPinModalType] = useState<PinModalType>(PinModalType.SET_PIN);

  const [isActivatePhysicalCardModalOpen, setIsActivatePhysicalCardModalOpen] =
    useState<boolean>(false);
  const [isReissueCardModalOpen, setIsReissueCardModalOpen] = useState<boolean>(false);
  const [isCancelCardModalOpen, setIsCancelCardModalOpen] = useState<boolean>(false);

  const [isCreationCompanyBankAccountModalOpen, setIsCreationCompanyBankAccountModalOpen] =
    useState<boolean>(false);

  const [marqetaWidgetUrl, setMarqetaWidgetUrl] = useState<string>("");

  const _marqetaInit = (clientAccessToken: string, cardId: string) => {
    const _marqetaWindow = window as unknown as IMarqetaWindow;

    const _config = getMarqetaConfig({
      clientAccessToken: clientAccessToken,
      onSuccess: () => {
        setIsCardViewLoading((prevState) => prevState.filter((it) => it !== cardId));
      },
      onFailure: (error: any) => {
        setIsCardViewLoading((prevState) => prevState.filter((it) => it !== cardId));
        setIsCardRevealed((prevState) => prevState.filter((it) => it !== cardId));
        console.log("Marqeta error", error);
      },
      cardId,
    });

    _marqetaWindow?.marqeta?.bootstrap(_config);
  };

  const _marqetaDestroy = () => {
    const _marqetaWindow = window as unknown as IMarqetaWindow;
    _marqetaWindow?.marqeta?.destroy?.();
  };

  useEffect(() => {
    if (currentUser) {
      initFetch();

      return () => {
        _marqetaDestroy();
      };
    }
  }, []);

  useEffect(() => {
    setIsCardRevealed([]);
    setMarqetaWidgetUrl("");
    setIsCardViewLoading([]);
  }, [currentTab, bankCards]);

  const initFetch = async () => {
    try {
      setIsLoading(true);
      let bankCardsListRes;
      if (userIsAdmin) {
        const result = await queryBankCardsControllerGetBankCard(
          {
            cardId: cardId || "",
          },
          {
            bankingType: requestsBankingType,
          },
        );
        bankCardsListRes = result ? [result] : [];
        const convertedData = convertCardDetailsToUserResponseDto(result);
        setCardOwner(convertedData);
        setCurrentTab(result.form === CardForm.VIRTUAL ? CARD_TABS.VIRTUAL : CARD_TABS.PHYSICAL);
      } else {
        const result = (bankCardsListRes = await queryBankCardsControllerListBankCards({
          bankingType: requestsBankingType,
        }));

        bankCardsListRes = result.cards || [];
      }

      const sortedBankCards = getSortedAndFilteredPersonalBankCards(bankCardsListRes);
      setBankCards(sortedBankCards || []);

      return bankCardsListRes;
    } catch (error: any) {
      if (error?.data?.error !== ErrorConst.USER_HAS_NO_PARTNER_BANK_ACCOUNT) {
        showErrorModal(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleRequestCard = async (cardType: CardForm) => {
    if (cardType === CardForm.PHYSICAL) {
      setIsCreationCompanyBankAccountModalOpen(true);
      return;
    }

    if (cardType === CardForm.VIRTUAL) {
      try {
        setIsLoading(true);
        const bankCardRes = await mutationBankCardsControllerIssueBankCard()({
          form: cardType,
          bankingType: requestsBankingType,
        });
        await handleSetPin(bankCardRes.id);
        return;
      } catch (error) {
        showErrorModal(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleSetPin = async (cardId: string, type?: PinModalType) => {
    //set and reset pin
    try {
      setIsLoading(true);
      setTargetBankCardId(cardId);
      if (type) {
        setPinModalType(type);
      }
      const _widgetUrl = await queryBankCardsControllerGetCardWidgetUrl(
        {
          cardId: cardId,
        },
        { widgetType: "set_pin" },
      );
      setMarqetaWidgetUrl(_widgetUrl.url);
      setIsSetPinModalOpen(true);
      return;
    } catch (error) {
      showErrorModal(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseSetPinModal = async () => {
    setIsSetPinModalOpen(false);
    setMarqetaWidgetUrl("");
    setIsCardViewLoading((prevState) => [...prevState, targetBankCardId]);

    try {
      const _alreadyExists = (bankCards || []).find((it) => it.id === targetBankCardId);
      const cardList = await initFetch();
      if (
        !_alreadyExists?.id &&
        cardList?.find((it) => it.id === targetBankCardId && it.isPinSet)
      ) {
        setCurrentTab(CARD_TABS.VIRTUAL);
      }
    } catch (error) {
      showErrorModal(error);
    } finally {
      setIsCardViewLoading((prevState) => prevState.filter((it) => it !== targetBankCardId));
      setTargetBankCardId("");
    }
  };

  const handleActivateCard = async (cardId: string) => {
    //activate physical card
    try {
      setIsLoading(true);
      setTargetBankCardId(cardId);

      const _widgetUrl = await queryBankCardsControllerGetCardWidgetUrl(
        {
          cardId: cardId,
        },
        { widgetType: "activate_card" },
      );
      setIsActivatePhysicalCardModalOpen(true);
      setMarqetaWidgetUrl(_widgetUrl.url);
      return;
    } catch (error) {
      showErrorModal(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseActivatePhysicalCardModal = async () => {
    setIsActivatePhysicalCardModalOpen(false);
    setMarqetaWidgetUrl("");
    try {
      await initFetch();
    } catch (error) {
      showErrorModal(error);
    } finally {
      setTargetBankCardId("");
    }
  };

  const handleOpenReissueCardModal = (cardId: string) => {
    setIsReissueCardModalOpen(true);
    setTargetBankCardId(cardId);
  };

  const handleReissueCard = async (
    reason: CardReissueReason,
    formikProps?: FormikProps<CompanyCardShippingDetailsFormType>,
  ) => {
    try {
      const { values, validateForm } = formikProps || {};
      const errors = await validateForm?.(values);
      if (!isEmpty(errors)) return;

      setIsReissueCardModalOpen(false);
      setIsLoading(true);
      const cardForm = currentTab === CARD_TABS.PHYSICAL ? CardForm.PHYSICAL : CardForm.VIRTUAL;
      const payload: IssueBankCardReqDto = {
        ...reissueCardFormValuesToRequestPayload(targetBankCardId, cardForm, reason, values),
        bankingType: requestsBankingType,
        userId: cardOwner?.userId,
      };
      await mutationBankCardsControllerIssueBankCard()(payload);
      AppEvents.emit("SetGlobalModal", {
        isOpen: true,
        type: EModalTypes.SUCCESS,
        title: t(`${translationPrefix}.reissue_card_modal.success.title`),
        message: t(`${translationPrefix}.reissue_card_modal.success.message`),
        mainButton: {
          text: t(`buttons.done`),
        },
      });
      if (userIsEmployee) {
        await initFetch();
        // It will close the modal;
        setTargetBankCardId("");
      } else {
        redirectToBankingPage();
      }
    } catch (error) {
      showErrorModal(error);
      setTargetBankCardId("");
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseReissueCardModal = () => {
    setIsReissueCardModalOpen(false);
  };

  const handleRevealCardViewDetails = async (cardId: string) => {
    try {
      setIsCardViewLoading((prevState) => [...prevState, cardId]);

      setIsCardRevealed((prevState) => [...prevState, cardId]);
      const accessToken = await mutationBankCardsControllerGetCardClientToken({
        cardId: cardId,
      })();
      await _marqetaInit(accessToken.clientToken, cardId);
    } catch (error) {
      showErrorModal(error);
      handleHideCardViewDetails(cardId);
    }
  };

  const handleHideCardViewDetails = (cardId: string) => {
    _marqetaDestroy();
    setIsCardRevealed((prevState) => prevState.filter((it) => it !== cardId));
    setIsCardViewLoading((prevState) => prevState.filter((it) => it !== cardId));
  };

  const handleOpenCancelCardModal = (cardId: string) => {
    setIsCancelCardModalOpen(true);
    setTargetBankCardId(cardId);
  };

  const handleCloseCancelCardModal = () => {
    setIsCancelCardModalOpen(false);
    setTargetBankCardId("");
  };

  const handleCancelCard = async () => {
    try {
      setIsLoading(true);
      setIsCancelCardModalOpen(false);
      await mutationBankCardsControllerUpdateCard(
        { cardId: targetBankCardId },
        { bankingType: requestsBankingType },
      )({
        cardStatus: CardStatusToUpdate.TERMINATED,
        reasonCode: CardStatusReason.REQ,
      });
      await initFetch();
    } catch (error) {
      showErrorModal(error);
    } finally {
      setTargetBankCardId("");
    }
  };

  const redirectToBankingPage = () => {
    navigate(routes.BANKING);
  };

  const handleBack = () => {
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1);
    } else {
      redirectToBankingPage();
      return;
    }
  };

  return {
    metadata: {
      translationPrefix,
      isLoading,
      targetBankCardId,
      currentUser,
      cardOwner,
    },
    pageData: {
      tabs: {
        currentTab,
        onChange: setCurrentTab,
      },
      bankCards,

      cardView: {
        isCardRevealed,
        isLoading: isCardViewLoading,
      },
    },
    actions: {
      handleRequestCard,
      handleActivateCard,
      handleRevealCardViewDetails,
      handleHideCardViewDetails,
      handleSetPin,
      handleBack,
      handleOpenReissueCardModal,
      handleReissueCard,
      handleOpenCancelCardModal,
      handleCancelCard,
      refetchContent: initFetch,
    },
    modals: {
      reissueCard: {
        isOpen: isReissueCardModalOpen,
        onClose: handleCloseReissueCardModal,
      },
      setPinModal: {
        isOpen: isSetPinModalOpen,
        onClose: handleCloseSetPinModal,
        marqetaUrl: marqetaWidgetUrl,
        pinModalType,
      },
      activatePhysicalCardModal: {
        isOpen: isActivatePhysicalCardModalOpen,
        onClose: handleCloseActivatePhysicalCardModal,
        marqetaUrl: marqetaWidgetUrl,
      },
      cancelCardModal: {
        isOpen: isCancelCardModalOpen,
        onClose: handleCloseCancelCardModal,
      },
      creationCompanyBankAccount: {
        isCreationCompanyBankAccountModalOpen,
        setIsCreationCompanyBankAccountModalOpen,
      },
    },
    permissions: {
      userIsAdmin,
      userIsEmployee,
      userIsAdvancedAdmin,
    },
  };
};
