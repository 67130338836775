import {
  CardFulfillmentStatus,
  CardReissueReason,
  CardStatus,
  CardStatusReason,
} from "types/CardShipping";

import { BankCardResDto } from "utils/swagger_react_query";

export enum EComprehensibleCardStatus { //More options will be added later
  EMPTY = "EMPTY",
  ACTIVE = "ACTIVE",
  ACTIVE_WITHOUT_PIN = "ACTIVE_WITHOUT_PIN",
  INACTIVE = "INACTIVE",
  CANCELED = "CANCELED",
  EXPIRED = "EXPIRED",
}

export enum CardAdditionalStatus {
  REISSUED = "REISSUED",
}

export interface IGetComprehensibleCardStatus {
  status: EComprehensibleCardStatus;
  additionalStatus?: CardAdditionalStatus;
}

export const getComprehensibleCardStatus = (data?: Partial<BankCardResDto>) => {
  const result: IGetComprehensibleCardStatus = {
    status: EComprehensibleCardStatus.EMPTY,
    additionalStatus: undefined,
  };

  if (!data) return result;
  const isReissued = getIsCardReissue(data);
  if (
    (data.cardStatus === CardStatus.TERMINATED && data.statusReason === CardStatusReason.REQ) ||
    data.statusReason === CardStatusReason.PRC ||
    [CardReissueReason.LOST, CardReissueReason.STOLEN].includes(
      data.reissueReason as CardReissueReason,
    )
  ) {
    //Card was canceled by user or reissued due to being lost or stolen
    result.status = EComprehensibleCardStatus.CANCELED;
  } else if (
    data.cardStatus === CardStatus.TERMINATED &&
    data.statusReason === CardStatusReason.EXP
  ) {
    //Card was expired
    result.status = EComprehensibleCardStatus.EXPIRED;
  } else if (
    data.cardStatus === CardStatus.REJECTED ||
    data.cardStatus === CardStatus.IMAGE_REJECTED ||
    data.cardStatus === CardStatus.UNACTIVATED
  ) {
    result.status = EComprehensibleCardStatus.INACTIVE;
  } else if (data.cardStatus === CardStatus.ACTIVE || data.reissuedToId) {
    if (!data.isPinSet) {
      result.status = EComprehensibleCardStatus.ACTIVE_WITHOUT_PIN;
    } else {
      result.status = EComprehensibleCardStatus.ACTIVE;
    }
  }

  if (isReissued) {
    result.additionalStatus = isReissued;
  }

  return result;
};

export const getIsCardReissue = (data?: Partial<BankCardResDto>) => {
  //Expired card is not reissued
  if (!data) return undefined;
  if (!!data.reissuedToId && !!data.reissueReason && data.statusReason !== CardStatusReason.EXP) {
    return CardAdditionalStatus.REISSUED;
  }

  return undefined;
};
