import styled from "styled-components";

import { breakpoint } from "helpers";
import CompanyCardShippingDetails from "components/CompanyBankAccountComponents/CompanyCardShippingDetails";

import { ButtonsContainer, SectionAltRounded, SectionTitle, UIModal } from "uikit";

export const StyledUIModal = styled(UIModal)`
  ${breakpoint("md")`
    width: 560px !important;
  `}
`;

export const ContentWrapper = styled.div`
  margin-bottom: 32px;
`;

export const ReasonSection = styled.div`
  margin-bottom: 24px;
`;

export const StyledPaidCardShippingDetails = styled(CompanyCardShippingDetails)`
  ${SectionAltRounded} {
    padding: 0;
    margin: 0;
    background: none;
  }
  ${SectionTitle} {
    text-align: left;
  }
`;

export const StyledButtonsContainer = styled(ButtonsContainer)`
  gap: 16px;

  button {
  }
`;
