import { ChevronLeftThin } from "assets/svg";
import styled from "styled-components";

import { breakpoint } from "helpers/shared/breakpoint";

import { SecondaryButtonIconed } from "uikit";

export const activeBorderWidth = 3;
export const vContainerPadding = 24;
export const hContainerPadding = 40;
export const vLargeContainerPadding = 24;
export const hLargeContainerPadding = 20;

export const activeBorderWidthNew = 1;
export const vContainerPaddingNew = 26;
export const hContainerPaddingNew = 24;

export const ContainerOuter = styled.div`
  margin-bottom: 20px;
  position: relative;
`;

export const ContainerOuterNew = styled(ContainerOuter)`
  margin-bottom: 16px;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding: ${vContainerPadding}px ${hContainerPadding}px;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.colors.navigationCardBg};
  border: 1px solid ${({ theme }) => theme.colors.contentBorder};
  min-height: 96px;
  box-sizing: border-box;
  position: relative;
  z-index: 1;

  &.active {
    border: ${activeBorderWidth}px solid ${({ theme }) => theme.colors.navigationCardBorder};
  }

  &.clickable {
    cursor: pointer;
  }

  ${breakpoint("xs", "lg")`
    padding: 24px;
    flex-direction: column;
    align-items: start;
    flex: 1;
  `}
`;

export const ContainerNew = styled.div`
  display: flex;
  align-items: center;
  padding: ${vContainerPaddingNew}px ${hContainerPaddingNew}px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.navigationCardBg};
  border: 1px solid ${({ theme }) => theme.colors.contentBorder};
  min-height: 76px;
  box-sizing: border-box;
  position: relative;
  z-index: 1;

  &.active {
    border: ${activeBorderWidthNew}px solid ${({ theme }) => theme.colors.navigationCardBorder};
  }

  &.clickable {
    cursor: pointer;
  }

  ${breakpoint("xs", "lg")`
    padding: 24px;
    flex-direction: column;
    align-items: start;
    flex: 1;
  `}

  &:hover {
    background-color: ${({ theme }) => theme.colors.sectionContainerAlt2}90; //TODO
    transition: background-color 0.3s;
  }
`;

export const ContainerLarge = styled(Container)`
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
`;

export const ContainerLargeInner = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  padding: ${vContainerPadding}px ${hContainerPadding}px;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${ContainerLarge} & {
    margin-bottom: 48px;
  }
`;

/* Need to restylize later */
export const ActiveLabel = styled.div`
  position: absolute;
  top: -${activeBorderWidth}px;
  right: -${activeBorderWidth}px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 60px;
  padding: 0 28px;

  border-radius: 0 16px 0 20px;
  height: 32px;

  background: ${({ theme }) => theme.colors.navigationCardActiveLabelBg};

  font-size: ${({ theme }) => theme.size.caption};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.navigationCardActiveLabelText};

  line-height: 1.35;
`;

export const AltLabel = styled(ActiveLabel)`
  background: ${({ theme }) => theme.colors.navigationCardAltLabelBg};
  color: ${({ theme }) => theme.colors.navigationCardAltLabelText};
`;

export const IconContainer = styled.span`
  width: 24px;
  height: 24px;
  margin-right: 8px;

  path {
    stroke: ${({ theme }) => theme.colors.accentMain};
  }
`;

export const MainContent = styled.div`
  flex: 1;

  ${breakpoint("xs", "lg")`
    margin-bottom: 12px;
  `}

  ${ContainerLarge} & {
    box-sizing: border-box;
    flex: none;
    width: 38%;
    padding-right: 16px;
    padding-bottom: ${vLargeContainerPadding}px;
  }
`;

export const MainContentNew = styled(MainContent)`
  display: flex;
  align-items: center;
`;

export const Title = styled.p`
  font-size: ${({ theme }) => theme.size.subtitle};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  color: ${({ theme }) => theme.colors.textAlt2};
  font-family: ${({ theme }) => theme.fontFamily.alt};
  line-height: 28px;

  ${ContainerLarge} & {
    font-size: ${({ theme }) => theme.size.title};
    line-height: 1.83;
  }
`;

export const Description = styled.p`
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.textAlt2};
  line-height: 1.75;
`;

export const AdditionalContent = styled.div`
  flex: 0.6;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 55px;
  margin-right: 55px;

  ${breakpoint("xs", "lg")`
    flex: 1;
    justify-content: flex-start;
    width: 100%;
  `}

  ${ContainerLarge} & {
    flex: none;
    width: 62%;
    height: 100%;
    box-sizing: border-box;
    margin-right: 0;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

export const LargeFooterContent = styled.div`
  width: 100%;
`;

export const ButtonContainer = styled.div``;

export const ViewButton = styled(SecondaryButtonIconed)`
  width: auto;
  min-width: 0;
  padding: 0 20px;
`;

export const ArrowButton = styled(ChevronLeftThin)`
  transform: rotate(180deg);
  cursor: pointer;
`;

export const BelowContentContainer = styled.div`
  z-index: 0;
`;
