import { ReactNode } from "react";
import { Trans } from "react-i18next";

import { IMappedBankAccount } from "types/BankAccounts";
import { BankAccountVerifyStatus } from "types/BankAccountVerifyStatus";
import { BankAccountType, PayDistributionType } from "types/BETypes";
import { getBankLogo } from "helpers";

import { RadioButtonAlt } from "uikit";

import { GetPlaidBankAccountsResponseDto } from "utils/swagger_react_query";

import { IRenderRadioBtnParams } from "./types";
import {
  BankInUseIcon,
  BankInUseLink,
  BankLogo,
  Column,
  Label,
  PaidBankLogo,
  PendingVerifyLink,
  RemoveBankAccountBtn,
  RemoveBankAccountIcon,
  StyledBankIconPlaceholder,
  Value,
  VerificationStatusValue,
  VerifyBtn,
} from "./styles";

const commonTranslationPrefix = `components.bank_account_components`;
const translationPrefix = `${commonTranslationPrefix}.bank_account_info_row`;

export const renderBaseLabelWithValue = (
  label: string | ReactNode,
  value: string | number | ReactNode | undefined,
) => {
  return (
    <Column>
      <Label>{label}</Label>
      <Value>{value}</Value>
    </Column>
  );
};

export const renderRadioBtn = ({
  name,
  id,
  value,
  onChange,
  isDisabled,
}: IRenderRadioBtnParams) => {
  return (
    <RadioButtonAlt
      name={name}
      id={id}
      value={value}
      onChange={onChange}
      isDisabled={isDisabled}
      data-testid={`default_bank_radio_button_${id}`}
    />
  );
};

export const renderBankLogo = (bankAccount?: IMappedBankAccount) => {
  if (bankAccount?._accountType === PayDistributionType.PARTNER_ACCOUNT) {
    return <PaidBankLogo />;
  }

  const bankLogo = getBankLogo(bankAccount as GetPlaidBankAccountsResponseDto);
  if (bankLogo) return <BankLogo src={bankLogo} alt="Bank logo" />;

  return <StyledBankIconPlaceholder />;
};

export const renderBankName = (
  bankAccount: IMappedBankAccount | undefined,
  onPendingVerificationLinkClick?: (status: BankAccountVerifyStatus) => void,
) => {
  const label = <Trans i18nKey={`${translationPrefix}.columns.name`} />;
  let value: string | ReactNode | undefined;
  const showPendingVerifyLink =
    [
      BankAccountVerifyStatus.PENDING_AUTOMATIC_VERIFICATION,
      BankAccountVerifyStatus.PENDING_MANUAL_VERIFICATION,
    ].includes(bankAccount?.verificationStatus as BankAccountVerifyStatus) &&
    onPendingVerificationLinkClick;

  if (bankAccount?.institution?.name && !showPendingVerifyLink)
    value = bankAccount?.institution?.name;
  if (showPendingVerifyLink)
    value = renderPendingVerifyLink(bankAccount, onPendingVerificationLinkClick);

  if (!value) {
    value = <Trans i18nKey={`${commonTranslationPrefix}.bank_name_placeholder`} />;
  }

  return renderBaseLabelWithValue(label, value);
};

export const renderPendingVerifyLink = (
  bankAccount: IMappedBankAccount | undefined,
  onClick: (verificationStatus: BankAccountVerifyStatus) => void,
) => {
  return (
    <PendingVerifyLink
      onClick={() => onClick(bankAccount?.verificationStatus as BankAccountVerifyStatus)}
      data-testid="pending_verify_link"
    >
      <Trans i18nKey={`${translationPrefix}.actions.pending_verification`} />
    </PendingVerifyLink>
  );
};

export const renderBankAccountName = (bankAccountName: string | ReactNode = "") => {
  const label = <Trans i18nKey={`${translationPrefix}.columns.bank_account_name`} />;
  const value = bankAccountName || (
    <Trans i18nKey={`${commonTranslationPrefix}.bank_name_placeholder`} />
  );
  return renderBaseLabelWithValue(label, value);
};

export const renderBankAccountNumber = (
  last4Digits: string = "",
  _accountType: PayDistributionType = PayDistributionType.EXTERNAL_ACCOUNT,
) => {
  const maskSize = _accountType === PayDistributionType.PARTNER_ACCOUNT ? 3 : 4;
  const label = <Trans i18nKey={`${translationPrefix}.columns.account_number`} />;
  const value = `${"*".repeat(maskSize)} ${last4Digits}`;
  return renderBaseLabelWithValue(label, value);
};

export const renderBankAccountType = (type: BankAccountType) => {
  const label = <Trans i18nKey={`${translationPrefix}.columns.type`} />;
  const value = <Trans i18nKey={`${commonTranslationPrefix}.account_type.${type}`} />;
  return renderBaseLabelWithValue(label, value);
};

export const renderBankAccountStatus = (status: BankAccountVerifyStatus) => {
  const label = <Trans i18nKey={`${translationPrefix}.columns.status`} />;
  const value = (
    <VerificationStatusValue className={status}>
      <Trans i18nKey={`${commonTranslationPrefix}.verification_status.${status}`} />
    </VerificationStatusValue>
  );
  return renderBaseLabelWithValue(label, value);
};

export const renderVerifyBtn = (onClick: () => void, renewLoginRequired: boolean) => {
  const verifyBtnLabel = renewLoginRequired ? "reconnect" : "verify";

  return (
    <VerifyBtn onClick={onClick} data-testid="bank_accounts_info">
      <Trans i18nKey={`${translationPrefix}.actions.${verifyBtnLabel}`} />
    </VerifyBtn>
  );
};

export const renderBankInUseLink = (onClick: () => void) => {
  return (
    <BankInUseLink onClick={onClick} data-testid="bank_accounts_info_row_bank_in_use_link">
      <BankInUseIcon />
      <Trans i18nKey={`${translationPrefix}.actions.bank_in_use`} />
    </BankInUseLink>
  );
};

export const renderRemoveBankAccountBtn = (
  onClick: () => void,
  bankAccount?: IMappedBankAccount,
) => {
  return (
    <RemoveBankAccountBtn
      onClick={onClick}
      data-testid={`bank_accounts_info_row_delete_button`}
      id={`bank_accounts_info_row_id-${bankAccount?.bankAccountId}`}
    >
      <RemoveBankAccountIcon />
    </RemoveBankAccountBtn>
  );
};
