import { AttentionTriangleFilledIcon } from "assets/svg";
import styled, { css } from "styled-components";

import { UserRole } from "types/BETypes";
import Avatar from "components/Avatar";

const optionHeight = 60;
const menuListHeight = optionHeight * 3;

const optionTextMiddleMixin = css`
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  color: ${({ theme }) => theme.colors.text};
  line-height: 18.2px;
  margin-bottom: 6px;
`;

const optionTextSmallMixin = css`
  font-size: ${({ theme }) => theme.size.small};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.textAlt};
  line-height: 15.6px;
`;

export const Container = styled.div`
  width: 100%;
  [class*="-Input"] {
    display: flex;
    width: 95%;

    input {
      width: 100%;
    }
  }

  div[class*="-menu"] {
    border: none !important;
    border-radius: 8px;
    width: 100%;
  }

  [class*="-MenuList"] {
    height: auto;
    max-height: ${menuListHeight}px;
  }

  [class*="-IndicatorsContainer"] {
    display: none;
  }
`;

export const CheckboxContainer = styled.div`
  margin-top: 8px;
`;

export const WarningSection = styled.div`
  margin-top: 8px;
  padding: 8px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.warningAlt3};
  display: flex;
  justify-content: space-between;
  gap: 16px;
`;

export const WSText = styled.p`
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.text};
  line-height: 24px;
  width: 95%;

  span {
    margin-top: 0;
  }
`;

export const WarningIcon = styled(AttentionTriangleFilledIcon)`
  width: 24px;
  height: 24px;
`;

export const StyledOption = styled.div`
  display: flex;
  padding: 10px 16px 10px 10px;
  border-radius: 8px;
  gap: 6px;
  height: ${optionHeight}px;
  cursor: pointer;
  transition: background 0.2s;

  &:hover {
    background: ${({ theme }) => theme.colors.inputDropdownHover}50;
  }
`;

export const OptionCell = styled.div`
  display: flex;
  align-items: center;

  &.max_content {
    flex: 1;
  }

  &.column {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
`;

export const OptionUserFullNameText = styled.p`
  ${optionTextMiddleMixin}
`;

export const OptionUserEmailText = styled.p`
  ${optionTextSmallMixin};
`;

export const OptionUserEmailTextHighlighted = styled.span`
  ${optionTextSmallMixin};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.colors.text};
`;

export const OptionUserRoleText = styled.p`
  ${optionTextSmallMixin};

  &.${UserRole.ADMIN}, &.${UserRole.OWNER}, &.${UserRole.SUPERADMIN} {
    background: ${({ theme }) => theme.colors.asyncUserSearchInputUserRoleAdminText};
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &.${UserRole.EMPLOYEE} {
    background: ${({ theme }) => theme.colors.asyncUserSearchInputUserRoleEmployeeText};
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

export const SelectedOptionContainer = styled(StyledOption)`
  margin-left: auto;
  margin-right: auto;
  width: calc(100% - 2px);
  margin-top: 8px;
  background: ${({ theme }) => theme.colors.contentBg};
  border: 1px solid ${({ theme }) => theme.colors.primary};
  height: ${optionHeight}px;

  &:hover {
    background: ${({ theme }) => theme.colors.contentBg};
    cursor: default;
  }
`;

export const StyledAvatar = styled(Avatar)`
  width: 32px;
  height: 32px;

  div,
  img {
    font-size: ${({ theme }) => theme.size.body1};
    width: 32px;
    height: 32px;
  }
`;
