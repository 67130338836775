import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FormikContextType } from "formik";
import { round } from "lodash";
import routes from "routes/routes";
import { useAppSelector } from "store/hooks";
import { userMetadataSelector } from "store/selectors";

import { BankAccountVerifyStatus } from "types/BankAccountVerifyStatus";
import { BankingType } from "types/BETypes";
import { HELP_EMAIL } from "constants/shared";
import { hasAdminRights, hasEmployeeRights } from "permissions/helpers/shared";
import { showErrorModal } from "helpers";
import { useBankAccountLink } from "hooks";
import { IBankAccountsSelectProps } from "components/BankAccountsSelect/BankAccountsSelect";
import { FormType as VerifyMFACodeFormType } from "components/MultiFactorAuthorization/components/sms/steps/VerifyCode/validationSchema";
import useMFASMS from "components/MultiFactorAuthorization/components/sms/useMFASMS";
import { MFAWidgetType } from "components/MultiFactorAuthorization/types";

import {
  CreateWithdrawalTransactionDto,
  GetPlaidBankAccountsResponseDto,
  mutationWithdrawalControllerCreateWithdrawalTransaction,
  queryBankCardsControllerGetSyncteraAccount,
  SyncteraAccountDto,
  WithdrawalTransactionDestinationDto,
} from "utils/swagger_react_query";

import { showNoMFAModal } from "../shared/modals";
import { TransferFundsFormType } from "./components";
import { ManualBankAccountAdditionalInfoFormType } from "./components/TransferModals/validationSchema";

interface IBankNumbersState {
  accountNumberHidden: boolean;
  routingNumberHidden: boolean;
}

export const useTransferFundsPage = () => {
  const translationPrefix = `banking_pages.shared.transfer_funds_page`;
  const navigate = useNavigate();
  const currentUser = useAppSelector(userMetadataSelector);
  const { t } = useTranslation();

  //User Permissions
  const userIsAdmin = hasAdminRights(currentUser);
  const userIsEmployee = hasEmployeeRights(currentUser);

  //Banking type for requests
  const requestsBankingType = userIsAdmin ? BankingType.BUSINESS : BankingType.PERSONAL;

  const [isLoading, setLoading] = useState<boolean>(true);
  const [isLoadingBankAccounts, setLoadingBankAccounts] = useState<boolean>(true);
  const [selectedBankAccount, setSelectedBankAccount] = useState<GetPlaidBankAccountsResponseDto>();
  const [lastTransferredBankAccount, setLastTransferredBankAccount] =
    useState<GetPlaidBankAccountsResponseDto>();
  const [svoc, setSvoc] = useState<boolean>(false);

  const [bankAccountDetails, setBankAccountDetails] = useState<SyncteraAccountDto>();

  const [transferConfirmationModal, setTransferConfirmationModal] = useState<boolean>(false);
  const [transferSuccessModal, setTransferSuccessModal] = useState<boolean>(false);
  const [manualBankAccountAdditionalInfoModal, setManualBankAccountAdditionalInfoModal] =
    useState<boolean>(false);
  const [bankAccountNumbersState, setBankAccountNumbersState] = useState<IBankNumbersState>({
    accountNumberHidden: true,
    routingNumberHidden: true,
  });
  const [transactionData, setTransactionData] =
    useState<Partial<WithdrawalTransactionDestinationDto>>();

  const { data, actions } = useBankAccountLink({
    loadingCallback: setLoadingBankAccounts,
  });

  const {
    factorsData: mfaFactorsData,
    currentFactor: currentMfaFactor,
    currentFactorChallenge,
    errorsPrefix: mfaErrorsPrefix,
    isLoading: isMFASMSLoading,
    createFactorChallenge,
    resendVerificationCode,
  } = useMFASMS({
    widgetType: MFAWidgetType.MISC,
  });

  const initFetch = async () => {
    try {
      const bankAccountsRes = await queryBankCardsControllerGetSyncteraAccount({
        bankingType: requestsBankingType,
      });
      setBankAccountDetails(bankAccountsRes);
    } catch (error) {
      showErrorModal(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (currentUser?.userId) {
      initFetch();
    }
  }, []);

  useEffect(() => {
    if (data.bankAccounts?.length) {
      const defaultAccount = data.bankAccounts?.find((it) => it.isDefault);

      setSelectedBankAccount(defaultAccount || data.bankAccounts?.[0]);
    }
  }, [data.bankAccounts]);

  const handleSelectBankAccount = (account: GetPlaidBankAccountsResponseDto) => {
    setSelectedBankAccount(account);
  };

  const openTransferConfirmationModal = async () => {
    if (!currentMfaFactor?.factorId) return;
    try {
      setLoading(true);
      await createFactorChallenge(currentMfaFactor.factorId);
      setTransferConfirmationModal(true);
    } catch (error) {
      showErrorModal(error);
    } finally {
      setLoading(false);
    }
  };

  const redirectToMFA = () => {
    navigate(
      userIsEmployee
        ? routes.EMPLOYEE_SETTINGS_MFA_AUTHENTICATION
        : routes.ADMIN_SETTINGS_MFA_AUTHENTICATION,
    );
  };

  const handleOpenConfirmationModal = (
    transactionValue?: TransferFundsFormType,
    additionalBankInfo?: ManualBankAccountAdditionalInfoFormType,
  ) => {
    if (mfaFactorsData === undefined) return;

    if (!currentMfaFactor) {
      showNoMFAModal(redirectToMFA);
      return;
    }

    setSvoc(true);
    if (transactionValue) {
      setTransactionData({
        amount: Number(transactionValue?.amount),
        description: transactionValue?.description,
      });
    }

    if (selectedBankAccount?.verificationStatus === BankAccountVerifyStatus.MANUALLY_VERIFIED) {
      if (additionalBankInfo) {
        setTransactionData({
          ...transactionData,
          manualBankName: additionalBankInfo?.bankName || "",
          manualOwnerName: additionalBankInfo?.bankHolderName || "",
        });
        setManualBankAccountAdditionalInfoModal(false);
        openTransferConfirmationModal();
      } else {
        setManualBankAccountAdditionalInfoModal(true);
      }
    } else {
      openTransferConfirmationModal();
    }
  };

  const handleSubmitTransferFunds = async (
    formikContext: FormikContextType<VerifyMFACodeFormType>,
  ) => {
    const { values, validateForm, setFieldError } = formikContext;
    if (!currentMfaFactor) {
      return;
    }

    validateForm(values).then(async (errors) => {
      const errorList = Object.values(errors);
      const hasErrors = !!errorList.length;

      if (!hasErrors) {
        if (!currentFactorChallenge?.challengeId) {
          showErrorModal();
          return;
        }

        setLoading(true);

        try {
          const payload: CreateWithdrawalTransactionDto = {
            destinations: [
              {
                amount: round(Number(transactionData?.amount) * 100),
                description: transactionData?.description || undefined,
                bankAccountId: selectedBankAccount?.bankAccountId || "",
                bankAccountVendor: "PLAID",
                manualBankName: transactionData?.manualBankName || undefined,
                manualOwnerName: transactionData?.manualOwnerName || undefined,
              },
            ],
            mfa: {
              challengeId: currentFactorChallenge?.challengeId,
              code: values.code || "",
            },
            bankingType: requestsBankingType,
          };

          try {
            await mutationWithdrawalControllerCreateWithdrawalTransaction()(payload);
          } catch (error) {
            setFieldError(
              "code",
              t(`${mfaErrorsPrefix}.${(error as any).data.error}`, { helpEmail: HELP_EMAIL }),
            );
            setLoading(false);
            return;
          }

          setLastTransferredBankAccount(selectedBankAccount);
          await initFetch();
          await actions.refetchBankAccountsList();
          setTransactionData(undefined);
          setTransferSuccessModal(true);
          setTransferConfirmationModal(false);
        } catch (error) {
          showErrorModal(error);
        } finally {
          setLoading(false);
        }
      }
    });
  };

  const redirectToBankingPage = () => {
    navigate(routes.BANKING);
  };

  const handleBack = () => {
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1);
    } else {
      redirectToBankingPage();
      return;
    }
  };

  const handleConfirmationModal = (state: boolean) => {
    if (state) {
      openTransferConfirmationModal();
    } else {
      setTransferConfirmationModal(false);
      setTransactionData(undefined);
    }
  };

  const handleVerificationCodeResend = (callback: () => void) => {
    resendVerificationCode(callback);
  };

  const bankAccountSelectProps: IBankAccountsSelectProps = {
    bankAccountsList: data.bankAccounts || [],
    selectedBankAccount: selectedBankAccount,
    onChange: handleSelectBankAccount,
    onAddAccount: actions.openModal,
    onVerify: actions.handleVerifyBankAccount,
  };

  return {
    metadata: {
      isLoading: isLoading || isMFASMSLoading,
      isLoadingBankAccounts,
      translationPrefix,
      svoc,
      setSvoc,
    },
    pageData: {
      paymentDetails: {
        bankAccountSelectProps,
        lastTransferredBankAccount,
      },
      bankAccountDetails: {
        bankAccountDetails,
        bankAccountNumbersState,
        setBankAccountNumbersState,
      },
    },
    actions: {
      handleSubmitTransferFunds,
      handleBack,
      handleOpenConfirmationModal,
      handleVerificationCodeResend,
    },
    modals: {
      transferConfirmationModal: {
        isOpen: transferConfirmationModal,
        setModal: handleConfirmationModal,
      },
      transferSuccessModal: {
        isOpen: transferSuccessModal,
        setModal: setTransferSuccessModal,
      },
      manualBankAccountAdditionalInfoModal: {
        isOpen: manualBankAccountAdditionalInfoModal,
        setModal: setManualBankAccountAdditionalInfoModal,
      },
    },
  };
};
