import { useEffect, useMemo, useState } from "react";
import { isEmpty } from "lodash";
import { useAppSelector } from "store/hooks";
import { userMetadataSelector } from "store/selectors";

import { getErrorMessageFromResponse } from "helpers/shared/errors";
import { isKycAccepted, isKycPending, isKycRejected } from "helpers/shared/kyc";
import useAuth from "hooks/useAuth";

import { KycVerifyReqDto, mutationBankCardsControllerKycVerify } from "utils/swagger_react_query";

import {
  getFailedKycUserDataFromLocalStorage,
  getKycResponseType,
  profileFormValuesToKycRequestPayload,
  removeFailedKycUserDataFromLocalStorage,
  setFailedKycUserDataToLocalStorage,
  userDetailsToKycProfileFormInitialValues,
} from "./helpers";
import { EKycResponseType, IHandleSubmitParams, IUseKYCVerificationParams } from "./types";

export const useKycVerification = ({
  onKybSubmitSuccess,
  onStatusSectionPrimaryBtnClick,
}: IUseKYCVerificationParams) => {
  const { getCurrentUser } = useAuth();
  const currentUser = useAppSelector(userMetadataSelector);
  const kycIsAccepted = isKycAccepted(currentUser);
  const kycIsPending = isKycPending(currentUser);
  const kycIsRejected = isKycRejected(currentUser);

  const formInitValues = useMemo(() => {
    return userDetailsToKycProfileFormInitialValues(currentUser);
  }, [currentUser]);

  const [errorMessage, setErrorMessage] = useState<string | null>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [responseType, setResponseType] = useState<EKycResponseType | null>(
    kycIsRejected ? EKycResponseType.ERROR : null,
  );

  useEffect(() => {
    if (currentUser?.kycStatus) {
      const type = getKycResponseType(currentUser);
      if (type) setResponseType(type);
    }
  }, [currentUser]);

  const handleSubmitKyc = async ({ values, validateForm }: IHandleSubmitParams) => {
    try {
      setIsSubmitting(true);
      const payload = profileFormValuesToKycRequestPayload(values);
      const erorrs = await validateForm();

      if (!isEmpty(erorrs)) {
        return;
      }

      const failedUserData: KycVerifyReqDto | undefined = getFailedKycUserDataFromLocalStorage();

      if (kycIsRejected && failedUserData) {
        const responseType = getKycResponseType(currentUser, payload, failedUserData);
        if (responseType === EKycResponseType.INCORRECT_INFO) {
          setResponseType(EKycResponseType.ERROR);
          return;
        }
      }

      await mutationBankCardsControllerKycVerify()(payload);
      const userResponse = await getCurrentUser();
      const responseType = getKycResponseType(userResponse);
      if (!responseType) throw new Error();
      if (responseType === EKycResponseType.SUCCESS) {
        removeFailedKycUserDataFromLocalStorage();
      } else if (responseType === EKycResponseType.ERROR) {
        setFailedKycUserDataToLocalStorage(payload);
      }
      setResponseType(responseType);
      onKybSubmitSuccess?.();
    } catch (error) {
      const _error = getErrorMessageFromResponse(error);
      setErrorMessage(_error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleStatusSectionPrimaryBtnClick = () => {
    if (kycIsRejected && responseType) {
      setResponseType(null);
    } else {
      onStatusSectionPrimaryBtnClick?.(responseType as EKycResponseType);
    }
  };

  return {
    kycStatusDetails: {
      kycIsAccepted,
      kycIsPending,
      kycIsRejected,
    },
    errorMessage,
    isSubmitting,
    responseType,
    formInitValues,
    handleSubmitKyc,
    handleStatusSectionPrimaryBtnClick,
  };
};
