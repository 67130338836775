import { FC, ReactNode } from "react";

import { Container, Content, IconContainer, Message, Title, WarningIcon } from "./styles";

interface IProps {
  showIcon?: boolean;
  title?: ReactNode;
  message?: ReactNode;
  bottomContent?: ReactNode;
  className?: string;
}

const WarningSection: FC<IProps> = ({
  showIcon = true,
  title,
  message,
  bottomContent,
  className,
}) => {
  return (
    <Container className={className}>
      {showIcon && (
        <IconContainer>
          <WarningIcon />
        </IconContainer>
      )}
      <Content>
        {title && <Title>{title}</Title>}
        {title && <Message>{message}</Message>}
        {bottomContent && bottomContent}
      </Content>
    </Container>
  );
};

export default WarningSection;
