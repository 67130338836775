import { FC } from "react";

import {
  getContainerClassName,
  renderAdditionalContent,
  renderArrowButton,
  renderBelowContent,
  renderIcon,
  renderTextBlock,
} from "./renderElements";
import { IProps } from "./types";
import { ContainerNew, ContainerOuterNew, MainContentNew } from "./styles";

const NavigationCardWithDetailsAlt: FC<IProps> = (props: IProps) => {
  const { id, onClick, button, isActive, additionalContent } = props;

  const _className = getContainerClassName(props);

  return (
    <ContainerOuterNew className={_className} data-testid={id ? `${id}_card` : undefined}>
      <ContainerNew onClick={onClick} className={_className}>
        <MainContentNew>
          {renderIcon(props)}
          {renderTextBlock(props)}
        </MainContentNew>

        {!!additionalContent && renderAdditionalContent(props)}

        {button || renderArrowButton(props)}

        {!!isActive && renderArrowButton(props)}
      </ContainerNew>

      {renderBelowContent(props)}
    </ContainerOuterNew>
  );
};

export default NavigationCardWithDetailsAlt;
