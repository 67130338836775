import { FC } from "react";
import { Trans } from "react-i18next";
import { PlusThin } from "assets/svg";

import { isAdminSetupComplete } from "helpers/setup/adminSetup";
import { isEmployeeSetupComplete } from "helpers/setup/employeeSetup";
import DashboardLayout from "layouts/DashboardLayout";
import { BankingDisclosureContainer } from "pages/Banking/shared/styles";
import BankingDisclosureComponent from "components/BankingDisclosureComponent";
import CompanyBankAccountCreationModal from "components/CompanyBankAccountComponents/CompanyBankAccountCreationModal";
import FinishCompanyBankAccountSetupBanner from "components/CompanyBankAccountComponents/FinishCompanyBankAccountSetupBanner";

import { Loader } from "uikit";

import useBankingPage from "./useBankingPage";
import { AddAccountButton } from "./styles";

interface IProps {}

const BankingPage: FC<IProps> = () => {
  const noStateTranslationPrefix = `components.payment_methods.no_account_state`;

  const {
    isLoading,
    istInitialDataRequestFinished,
    requestsLoading,
    currentUser,
    currentCompany,
    isCreationCompanyBankAccountModalOpen,
    cardsList,
    factorsData,
    userHasCompanyAccount,
    userIsEmployee,
    isMFAEnabled,
    shouldUserOrderPhysicalCard,
    handleAddAccount,
    renderContent,
    setIsCreationCompanyBankAccountModalOpen,
    refetchData,
  } = useBankingPage();

  const shouldShowFinishSetupBanner = () => {
    if (userIsEmployee) {
      return (
        !isEmployeeSetupComplete(currentUser, cardsList, factorsData) && !!userHasCompanyAccount
      );
    } else {
      return (
        !isAdminSetupComplete(currentCompany, cardsList, factorsData) && !!userHasCompanyAccount
      );
    }
  };

  const showFinishSetupBanner = shouldShowFinishSetupBanner();

  return (
    <DashboardLayout
      titleRightContent={
        <>
          {!requestsLoading && !userHasCompanyAccount && userIsEmployee && (
            <AddAccountButton
              onClick={handleAddAccount}
              data-testid="banking-page-create-company-account-button"
            >
              <PlusThin />
              <Trans i18nKey={`${noStateTranslationPrefix}.create_account_button`} />
            </AddAccountButton>
          )}
        </>
      }
    >
      <>
        {isLoading && <Loader />}

        {!isLoading && !!istInitialDataRequestFinished && !!showFinishSetupBanner && (
          <FinishCompanyBankAccountSetupBanner onContinueSetupClick={handleAddAccount} />
        )}

        {!isLoading && renderContent()}

        {!requestsLoading && (
          <BankingDisclosureContainer>
            <BankingDisclosureComponent />
          </BankingDisclosureContainer>
        )}
      </>

      <CompanyBankAccountCreationModal
        isOpen={isCreationCompanyBankAccountModalOpen}
        setIsOpen={setIsCreationCompanyBankAccountModalOpen}
        onFinished={refetchData}
        refetchPageContentCallback={refetchData}
        hideHeader={shouldUserOrderPhysicalCard && isMFAEnabled}
        isShippingStepOnly={shouldUserOrderPhysicalCard && isMFAEnabled}
        data={{
          bankCardsList: cardsList || [],
        }}
      />
    </DashboardLayout>
  );
};

export default BankingPage;
