import { BankAccountVerifyStatus } from "types/BankAccountVerifyStatus";
import { BankAccountType } from "types/BETypes";

export const bankAccountComponentsTranslation = {
  "en-US": {
    buttons: {
      connect_bank_account: "Connect Bank Account",
      add_new_bank: "Add New Bank",
    },
    bank_name_placeholder: "No Bank Name",
    paid_bank_name: "Paid Checking Account",
    paid_bank_account_name: "Paid",
    verification_status: {
      [BankAccountVerifyStatus.VERIFICATION_FAILED]: "Verification Failed",
      [BankAccountVerifyStatus.VERIFICATION_EXPIRED]: "Verification Expired",
      [BankAccountVerifyStatus.AUTOMATICALLY_VERIFIED]: "Verified",
      [BankAccountVerifyStatus.INSTANTLY_VERIFIED]: "Verified",
      [BankAccountVerifyStatus.MANUALLY_VERIFIED]: "Verified",
      [BankAccountVerifyStatus.PENDING_AUTOMATIC_VERIFICATION]: "Pending Automatic Verification",
      [BankAccountVerifyStatus.PENDING_MANUAL_VERIFICATION]: "Pending Manual Verification",
    },
    account_type: {
      [BankAccountType.brokerage]: "",
      [BankAccountType.credit]: "Credit",
      [BankAccountType.depository]: "Deposit",
      [BankAccountType.investment]: "",
      [BankAccountType.loan]: "",
      [BankAccountType.other]: "",
    },
    external_accounts_list_section: {
      title: "External Accounts",
      empty_state: {
        message:
          "<1>You have no External Bank Account</1><2>Would you like to connect your bank account?</2>",
        button: "Connect External Bank Account",
      },
      connect_bank_account_link_btn: "Connect Bank Account",
    },
    bank_account_info_row: {
      columns: {
        name: "Name",
        bank_account_name: "Bank Account Name",
        account_number: "Account Number",
        type: "Type",
        status: "Status",
      },
      actions: {
        bank_in_use: "Bank in Use",
        verify: "Verify",
        reconnect: "Reconnect",
        pending_verification: "Pending Verification...",
      },
    },
    bank_account_connection_method_modal: {
      title: "Connect Bank Account",
      methods: {
        paid: {
          title: "Paid Checking Account",
          description:
            "Earn 2.50% interest, get 25% of Net Pay 2 days early, plus ZERO hidden fees. <1>Learn More</1>",
        },
        external: {
          title: "Other Bank Account",
          description:
            "Connect your existing bank account for payroll purposes, using your preferred banking institution.",
        },
      },
    },
    external_bank_account_modals: {
      success_added: {
        title: "Success!",
        message: "Your bank was successfully connected",
      },
      success_verified: {
        title: "Success!",
        message: "Your bank was successfully verified",
      },
      remove_conformation: {
        title: "Are you sure you want to delete?",
        message: "This action cannot be undone",
      },
      bank_in_use_delete_modal: {
        title: "Warning",
        message:
          "This bank account is currently used for pay distribution. Please update your settings on the Pay Distribution page before attempting to delete this account.",
        btn: "Go to Pay Distribution",
      },
      pending_verification: {
        [BankAccountVerifyStatus.PENDING_AUTOMATIC_VERIFICATION]: {
          title: "Pending automatic verification",
          message:
            "We've received your request to connect your bank account using automatic micro-deposit verification.",
        },
        [BankAccountVerifyStatus.PENDING_MANUAL_VERIFICATION]: {
          title: "Pending manual verification",
          message:
            "We’ve received your request to connect your bank account using micro-deposit verification.",
          text_separator: "What’s next:",
          message_2:
            "Over the next 2-3 business days you will receive two small deposits to your bank account. Once these deposits are made, you will get an email with instructions on how to complete your account verification.",
        },
      },
    },
    paid_accounts_list_section: {
      more_info_btn: "More Info",
      continue_setup_btn: "Continue Setup",
      title: "Paid Bank Account",
    },
    paid_bank_account_modals: {
      successfully_shipped: {
        title: "Card Ordered",
        message: "Your physical card has been successfully shipped! ",
      },
    },
  },
};

export default bankAccountComponentsTranslation;
