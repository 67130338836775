import { FC } from "react";
import { Trans } from "react-i18next";

import { CardForm, CardStatus } from "types/CardShipping";
import DashboardLayout from "layouts/DashboardLayout";
import BankingDisclosureComponent from "components/BankingDisclosureComponent";
import CompanyBankAccountCreationModal from "components/CompanyBankAccountComponents/CompanyBankAccountCreationModal";

import { FullScreenLoader, TabsContainer } from "uikit";
import { EButtonsFlex, EModalTypes } from "uikit/Modal";

import { BankingDisclosureContainer } from "../../shared/styles";
import { CardDetails, ReissuedCardModal, RequestCard, SetPinModal } from "./components";
import { ActivatePhysicalCardModal } from "./components/ActivatePhysicalCardModal/ActivatePhysicalCardModal";
import { CARD_TABS } from "./constants";
import { useCardsDetailsPage } from "./useCardsDetailsPage";
import {
  BackBtn,
  BackButtonContainer,
  CancelCardModal,
  Container,
  Content,
  PhysicalCardDetailsContainer,
  VirtualCardDetailsContainer,
} from "./styles";

const CardsDetailsPage: FC = () => {
  const { metadata, pageData, actions, modals, permissions } = useCardsDetailsPage();
  const { userIsAdmin, userIsEmployee } = permissions;
  const { translationPrefix } = metadata;

  const renderPhysicalCardDetails = () => {
    const hasCards = !!pageData.bankCards?.length;
    const physicalCards = (pageData.bankCards || []).filter(
      (card) => card.form === CardForm.PHYSICAL,
    );
    const shouldShowRequestPhysicalCard =
      userIsEmployee &&
      pageData.bankCards &&
      !physicalCards.filter((card) =>
        [CardStatus.ACTIVE, CardStatus.UNACTIVATED].includes(card.cardStatus as CardStatus),
      ).length;

    return (
      <PhysicalCardDetailsContainer>
        {shouldShowRequestPhysicalCard && (
          <>
            <RequestCard
              originTranslationPrefix={translationPrefix}
              cardType={CardForm.PHYSICAL}
              onRequestClick={() => actions.handleRequestCard(CardForm.PHYSICAL)}
            />

            <CompanyBankAccountCreationModal
              isOpen={modals.creationCompanyBankAccount.isCreationCompanyBankAccountModalOpen}
              setIsOpen={modals.creationCompanyBankAccount.setIsCreationCompanyBankAccountModalOpen}
              onFinished={actions.refetchContent}
              hideHeader={hasCards}
              isShippingStepOnly={hasCards}
              refetchPageContentCallback={actions.refetchContent}
              data={{
                bankCardsList: pageData.bankCards,
              }}
            />
          </>
        )}
        {physicalCards.map((card) => (
          <CardDetails
            key={card.id}
            originTranslationPrefix={translationPrefix}
            cardDetails={card}
            cardView={{
              isRevealed: !!pageData.cardView.isCardRevealed.includes(card.id),
              onReveal: () => actions.handleRevealCardViewDetails(card.id),
              onHide: () => actions.handleHideCardViewDetails(card.id),
              isLoading: !!pageData.cardView.isLoading.includes(card.id),
            }}
            actions={{
              onActivateCard: () => actions.handleActivateCard(card.id),
              onSetPin: (type) => actions.handleSetPin(card.id, type),
              onReissueCard: () => actions.handleOpenReissueCardModal(card.id),
              onCancelCard: () => actions.handleOpenCancelCardModal(card.id),
            }}
          />
        ))}
      </PhysicalCardDetailsContainer>
    );
  };

  const renderVirtualCardDetails = () => {
    const virtualCards = (pageData.bankCards || []).filter(
      (card) => card.form === CardForm.VIRTUAL,
    );
    const shouldShowRequestVirtualCard =
      userIsEmployee &&
      pageData.bankCards &&
      !virtualCards.filter((card) => [CardStatus.ACTIVE].includes(card.cardStatus as CardStatus))
        .length;

    return (
      <VirtualCardDetailsContainer>
        {shouldShowRequestVirtualCard && (
          <RequestCard
            originTranslationPrefix={translationPrefix}
            cardType={CardForm.VIRTUAL}
            onRequestClick={() => actions.handleRequestCard(CardForm.VIRTUAL)}
          />
        )}
        {virtualCards.map((card) => (
          <CardDetails
            key={card.id}
            originTranslationPrefix={translationPrefix}
            cardDetails={card}
            cardView={{
              isRevealed: !!pageData.cardView.isCardRevealed.includes(card.id),
              onReveal: () => actions.handleRevealCardViewDetails(card.id),
              onHide: () => actions.handleHideCardViewDetails(card.id),
              isLoading: !!pageData.cardView.isLoading.includes(card.id),
            }}
            actions={{
              onSetPin: (type) => actions.handleSetPin(card.id, type),
              onReissueCard: () => actions.handleOpenReissueCardModal(card.id),
              onCancelCard: () => actions.handleOpenCancelCardModal(card.id),
            }}
          />
        ))}
      </VirtualCardDetailsContainer>
    );
  };

  const tabs = [
    {
      id: "card-details-tab-physical",
      label: `${translationPrefix}.tab_labels.${CardForm.PHYSICAL}`,
      index: CARD_TABS.PHYSICAL,
      content: renderPhysicalCardDetails(),
    },
    {
      id: "card-details-tab-virtual",
      label: `${translationPrefix}.tab_labels.${CardForm.VIRTUAL}`,
      index: CARD_TABS.VIRTUAL,
      content: renderVirtualCardDetails(),
    },
  ];

  return (
    <DashboardLayout>
      {metadata.isLoading && <FullScreenLoader />}
      <Container>
        <Content>
          <TabsContainer
            tabs={tabs}
            selectedTabIndex={pageData.tabs.currentTab}
            onSelectTab={(index: CARD_TABS) => pageData.tabs.onChange(index)}
            hideTabs={userIsAdmin}
          />
        </Content>

        <BackButtonContainer>
          <BackBtn onClick={actions.handleBack}>
            <Trans i18nKey={`buttons.back`} />
          </BackBtn>
        </BackButtonContainer>

        <BankingDisclosureContainer>
          <BankingDisclosureComponent />
        </BankingDisclosureContainer>
      </Container>

      {metadata.targetBankCardId && (
        <>
          <SetPinModal
            originTranslationPrefix={translationPrefix}
            marqetaUrl={modals.setPinModal.marqetaUrl}
            isOpen={modals.setPinModal.isOpen}
            onClose={modals.setPinModal.onClose}
            type={modals.setPinModal.pinModalType}
          />

          {modals.reissueCard.isOpen && (
            <ReissuedCardModal
              originTranslationPrefix={translationPrefix}
              isOpen={modals.reissueCard.isOpen}
              onClose={modals.reissueCard.onClose}
              handleSubmit={actions.handleReissueCard}
              skipShippingDetailsStep={pageData.tabs.currentTab === CARD_TABS.VIRTUAL}
              cardForm={
                pageData.tabs.currentTab === CARD_TABS.PHYSICAL
                  ? CardForm.PHYSICAL
                  : CardForm.VIRTUAL
              }
              adminCardholderReissueData={metadata.cardOwner}
            />
          )}

          {pageData.tabs.currentTab === CARD_TABS.PHYSICAL && (
            <ActivatePhysicalCardModal
              originTranslationPrefix={translationPrefix}
              marqetaUrl={modals.activatePhysicalCardModal.marqetaUrl}
              isOpen={modals.activatePhysicalCardModal.isOpen}
              onClose={modals.activatePhysicalCardModal.onClose}
            />
          )}

          <CancelCardModal
            isOpen={modals.cancelCardModal.isOpen}
            onClose={modals.cancelCardModal.onClose}
            type={EModalTypes.DELETE}
            title={<Trans i18nKey={`${translationPrefix}.cancel_card_modal.title`} />}
            message={<Trans i18nKey={`${translationPrefix}.cancel_card_modal.message`} />}
            mainButton={{
              text: <Trans i18nKey={`${translationPrefix}.cancel_card_modal.cancel_card`} />,
              onClick: actions.handleCancelCard,
            }}
            secondaryButton={{
              text: <Trans i18nKey={`buttons.no_thanks`} />,
              onClick: modals.cancelCardModal.onClose,
            }}
            buttonsFlex={EButtonsFlex.ROW_REVERSE}
          ></CancelCardModal>
        </>
      )}
    </DashboardLayout>
  );
};

export default CardsDetailsPage;
