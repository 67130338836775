export enum ExternalBankAccountInfoRowColumn {
  DEFAULT_BANK_RADIO_BTN = "DEFAULT_BANK_RADIO_BTN",
  NAME = "NAME",
  BANK_ACCOUNT_NAME = "BANK_ACCOUNT_NAME",
  ACCOUNT_NUMBER = "ACCOUNT_NUMBER",
  TYPE = "TYPE",
  STATUS = "STATUS",
}

export enum PaidBankAccountInfoRowColumn {
  DEFAULT_BANK_RADIO_BTN = "DEFAULT_BANK_RADIO_BTN",
  NAME = "NAME",
  BANK_ACCOUNT_NAME = "BANK_ACCOUNT_NAME",
  ACCOUNT_NUMBER = "ACCOUNT_NUMBER",
}

export interface IRenderRadioBtnParams {
  id: string;
  name: string;
  value: string;
  isDisabled?: boolean;
  onChange: (id: string) => void;
}
