import styled from "styled-components";

import { EBusinessStructureUserStatus } from "types/BETypes";

export const Wrapper = styled.div``;

export const StringWrapper = styled.div`
  display: inline-block;
  font-size: ${({ theme }) => theme.size.caption};
  font-weight: ${({ theme }) => theme.fontWeight.bold};

  &.${EBusinessStructureUserStatus.ACTIVE} {
    color: ${({ theme }) => theme.colors.businessStructureUserStatusActive};
  }

  &.${EBusinessStructureUserStatus.INVITED} {
    color: ${({ theme }) => theme.colors.businessStructureUserStatusInvited};
  }

  &.${EBusinessStructureUserStatus.NOT_INVITED} {
    color: ${({ theme }) => theme.colors.businessStructureUserStatusNotInvited};
  }
`;

export const Label = styled.span`
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  white-space: nowrap;

  &.${EBusinessStructureUserStatus.ACTIVE} {
    color: ${({ theme }) => theme.colors.businessStructureUserStatusActive};
  }

  &.${EBusinessStructureUserStatus.INVITED} {
    color: ${({ theme }) => theme.colors.businessStructureUserStatusInvited};
  }

  &.${EBusinessStructureUserStatus.NOT_INVITED} {
    color: ${({ theme }) => theme.colors.businessStructureUserStatusNotInvited};
  }
`;
