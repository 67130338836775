import { useState } from "react";
import { useAppSelector } from "store/hooks";
import { companyMetadataSelector, userMetadataSelector } from "store/selectors";

import { DefaultBankAccountType, ErrorConst } from "types/BETypes";
import { hasAdminRights } from "permissions/helpers/shared";
import { showErrorModal } from "helpers";

import {
  GetPlaidBankAccountsResponseDto,
  mutationCompaniesControllerSetEmployerDefaultBankAccount,
  SyncteraAccountDto,
} from "utils/swagger_react_query";

interface IUseDefaultBankAccountParams {
  onChangeDefaultBankAccountCallback?: () => void;
  loadingCallback: (state: boolean) => void;
  determineDefaultBankAccountIdOnMount?: boolean;
}

export const useDefaultBankAccount = ({
  onChangeDefaultBankAccountCallback,
  loadingCallback,
}: IUseDefaultBankAccountParams) => {
  const [defaultBankAccountId, setDefaultBankAccountId] = useState<string | null>(null);
  const currentUser = useAppSelector(userMetadataSelector);
  const currentCompany = useAppSelector(companyMetadataSelector);
  const userIsAdmin = hasAdminRights(currentUser);

  const determineDefaultBankAccountId = (
    internalBankAccount: SyncteraAccountDto | undefined,
    externalBankAccounts: GetPlaidBankAccountsResponseDto[] | undefined,
  ) => {
    if (currentCompany?.defaultBankAccountType === DefaultBankAccountType.EXTERNAL_ACCOUNT) {
      const defaultBankAccount = externalBankAccounts?.find((it) => it.isDefault);
      setDefaultBankAccountId(defaultBankAccount?.bankAccountId || "");
    } else if (currentCompany?.defaultBankAccountType === DefaultBankAccountType.PARTNER_ACCOUNT) {
      setDefaultBankAccountId(internalBankAccount?.id || "");
    }
  };

  const handleSetDefaultBankAccount = async (
    bankAccountId: string,
    type: DefaultBankAccountType,
  ) => {
    try {
      if (!userIsAdmin) throw new Error(ErrorConst.INSUFFICIENT_PERMISSIONS);

      loadingCallback(true);
      await mutationCompaniesControllerSetEmployerDefaultBankAccount({
        companyId: currentUser?.companyId || "",
      })({
        accountId: bankAccountId,
        type: type,
      });
      setDefaultBankAccountId(bankAccountId);

      await onChangeDefaultBankAccountCallback?.();
    } catch (error) {
      console.log(error);
      showErrorModal(error);
    } finally {
      loadingCallback(false);
    }
  };

  return {
    determineDefaultBankAccountId,
    handleSetDefaultBankAccount,
    defaultBankAccountId,
  };
};
