import { FC } from "react";
import { Trans } from "react-i18next";
import { PlusThin } from "assets/svg";
import { isEmpty } from "lodash";

import { DefaultBankAccountType, UserRole } from "types/BETypes";
import { checkSetupCompletion } from "helpers/setup/sharedSetup";
import DashboardLayout from "layouts/DashboardLayout";
import { BankingDisclosureContainer } from "pages/Banking/shared/styles";
import {
  AgreementBlock,
  AgreementBlockMessage,
  AgreementBlockTitle,
  AgreementCheckbox,
} from "pages/Setup/AdminSetupPage/steps/ConnectBank";
import {
  BankAccountConnectionMethodModal,
  ExternalBankAccountsSection,
  PaidBankAccountSection,
} from "components/BankAccountComponents";
import BankingDisclosureComponent from "components/BankingDisclosureComponent";
import CompanyBankAccountCreationModal from "components/CompanyBankAccountComponents/CompanyBankAccountCreationModal";
import PermissionContent from "components/PermissionContent";

import { Loader } from "uikit";

import { useBankAccountsPage } from "./useBankAccountsPage";
import { AddAccountButton, BackButton } from "./styles";

interface IProps {}

const BankAccountsPage: FC<IProps> = () => {
  const { metadata, pageData, actions, modals } = useBankAccountsPage();
  const { translationPrefix } = metadata;
  const agreementCheckboxTranslationKey = `admin_setup_page.steps.connect_bank.agreement_text`;

  const setupHasCompleted = checkSetupCompletion({
    currentUser: metadata.currentUser,
    currentCompany: metadata.currentCompany,
    internalBankCards: pageData.bankCards,
    factors: pageData.factorsData || [],
  });

  return (
    <DashboardLayout
      showResponsiveMenu={false}
      onBackBtnClick={() => actions.handleBackBtnClick()}
      titleRightContent={
        <AddAccountButton
          data-testid={"add_bank_account_btn"}
          onClick={actions.handleOpenChooseMethodModal}
        >
          <PlusThin />
          <Trans i18nKey={`${translationPrefix}.connect_bank_account_btn`} />
        </AddAccountButton>
      }
    >
      {metadata.isLoading ? (
        <Loader />
      ) : (
        <>
          <PaidBankAccountSection
            onBankInUseClick={actions.onBankInUseClick}
            bankAccounts={
              !isEmpty(pageData.companyBankAccount) ? [pageData.companyBankAccount] : []
            }
            onBannerBtnClick={actions.onPaidBankBannerBtnClick}
            setupCompleted={setupHasCompleted}
            onContinueSetupClick={actions.onPaidBankBannerBtnClick}
            onChangeDefaultBank={(id) =>
              actions.handleSetDefaultBankAccount(id, DefaultBankAccountType.PARTNER_ACCOUNT)
            }
            defaultBankAccountId={pageData.defaultBankAccountId || ""}
          />

          <ExternalBankAccountsSection
            bankAccounts={pageData.externalBankAccounts.bankAccounts || []}
            onConnectBankAccount={actions.externalBankAccounts.openModal}
            onRemoveBankAccount={actions.externalBankAccounts.handleRemoveBankAccount}
            onVerifyBankAccount={actions.externalBankAccounts.handleVerifyBankAccount}
            onBankInUseClick={actions.onBankInUseClick}
            onChangeDefaultBank={(id) =>
              actions.handleSetDefaultBankAccount(id, DefaultBankAccountType.EXTERNAL_ACCOUNT)
            }
            defaultBankAccountId={pageData.defaultBankAccountId || ""}
          />

          <PermissionContent
            roles={[UserRole.EMPLOYEE]}
            elseContent={
              <AgreementBlock>
                <AgreementCheckbox
                  checked={true}
                  text={
                    <Trans
                      i18nKey={`${agreementCheckboxTranslationKey}`}
                      components={{
                        1: <AgreementBlockTitle />,
                        2: <AgreementBlockMessage />,
                      }}
                      values={{
                        companyName: metadata.currentCompany?.name || "",
                      }}
                    />
                  }
                />
              </AgreementBlock>
            }
          />

          <BackButton onClick={actions.handleBackBtnClick} data-testid={"back-button"}>
            <Trans i18nKey={`buttons.back`} />
          </BackButton>

          <BankingDisclosureContainer>
            <BankingDisclosureComponent />
          </BankingDisclosureContainer>
        </>
      )}

      {modals.bankAccountConnectionMethodModal.isBankAccountConnectionMethodModalOpen && (
        <BankAccountConnectionMethodModal
          isOpen={modals.bankAccountConnectionMethodModal.isBankAccountConnectionMethodModalOpen}
          onClose={() =>
            modals.bankAccountConnectionMethodModal.setBankAccountConnectionMethodModalOpen(false)
          }
          onContinue={actions.handleChooseMethod}
        />
      )}

      <CompanyBankAccountCreationModal
        isOpen={modals.companyBankAccountCreation.isCreationCompanyBankAccountModalOpen}
        setIsOpen={modals.companyBankAccountCreation.setIsCreationCompanyBankAccountModalOpen}
        onFinished={actions.initFetch}
        hideHeader={metadata.shouldUserOrderPhysicalCard && metadata.isMFAEnabled}
        isShippingStepOnly={metadata.shouldUserOrderPhysicalCard && metadata.isMFAEnabled}
        refetchPageContentCallback={actions.initFetch}
        data={{
          bankCardsList: pageData.bankCards,
        }}
      />
    </DashboardLayout>
  );
};

export default BankAccountsPage;
