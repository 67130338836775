import { Trans } from "react-i18next";

import { AppEvents } from "services/events";

import { EModalTypes, IModalProps } from "uikit/Modal/types";

import { EKycResponseType } from "./types";

import "./styles.scss";

export interface IShowModalProps {
  type: EKycResponseType;
  onMainButtonClick?: () => void;
  onSecondaryButtonClick?: () => void;
  onCloseClick?: () => void;
}

export const useKycStatusModals = () => {
  const prefix = `components.company_bank_account_components.shared.kyb_kyc_response_section.verification_statuses`;

  const hideModal = () => {
    AppEvents.emit("CloseGlobalModal", {});
  };

  const getModalConfig = (type: EKycResponseType): Partial<IModalProps> | undefined => {
    if (type === EKycResponseType.VERIFICATION_IS_TAKING_TOO_LONG) {
      return {
        type: EModalTypes.PENDING,
        title: (
          <Trans
            i18nKey={`${prefix}.${EKycResponseType.VERIFICATION_IS_TAKING_TOO_LONG}.title`}
          ></Trans>
        ),
        message: (
          <Trans
            i18nKey={`${prefix}.${EKycResponseType.VERIFICATION_IS_TAKING_TOO_LONG}.message`}
          ></Trans>
        ),
        mainButton: {
          onClick: () => undefined,
        },
      };
    }
  };

  const showModal = ({
    type,
    onMainButtonClick,
    onSecondaryButtonClick,
    onCloseClick,
  }: IShowModalProps) => {
    const modalProps = getModalConfig(type);
    if (!modalProps) return;

    AppEvents.emit("SetGlobalModal", {
      className: "kyc-status-modal",
      isOpen: true,
      ...modalProps,
      mainButton: modalProps.mainButton
        ? {
            ...(modalProps.mainButton || {}),
            text: modalProps.mainButton?.text || <Trans i18nKey={"common.modal.done"}></Trans>,
            onClick: onMainButtonClick || modalProps.mainButton?.onClick,
          }
        : undefined,
      secondaryButton: modalProps.secondaryButton
        ? {
            ...(modalProps.secondaryButton || {}),
            text: modalProps.secondaryButton?.text || (
              <Trans i18nKey={"common.modal.cancel"}></Trans>
            ),
            onClick: onSecondaryButtonClick || modalProps.secondaryButton?.onClick,
          }
        : undefined,
      onClose: onCloseClick || hideModal,
    });
  };

  return {
    EKycResponseType,
    showModal,
    hideModal,
  };
};
