import { useEffect, useState } from "react";
import { useAppSelector } from "store/hooks";
import { companyMetadataSelector } from "store/selectors";

import { SalsaOnboardingStatus } from "types/BETypes";
import { showErrorModal } from "helpers";
import { getDeferredData } from "helpers/employee/employeeRequests";
import useAuth from "hooks/useAuth";

import {
  CompanyResponseDto,
  mutationEmployeesControllerListEmployeesByFilter,
  mutationPlaidControllerAcceptDisclosure,
} from "utils/swagger_react_query";

import { STEPS } from "./constants";

export const useAdminSetup = () => {
  const currentCompany = useAppSelector(companyMetadataSelector);

  const [isLoading, setLoading] = useState<boolean>(true);
  const [currentStep, setCurrentStep] = useState<number | undefined>(undefined);
  const [showWelcomeMessage, setShowWelcomeMessage] = useState<boolean>(false);
  const { getCompanyInfo, saveCompanyToStore } = useAuth();
  const [workersRequestedTotal, setWorkersRequestedTotal] = useState<number | undefined>(undefined);
  const [isContinueToOnboardingDisabled, setContinueToOnboardingDisabled] = useState<
    Record<STEPS, boolean>
  >({
    [STEPS.ADD_WORKER]: true,
    [STEPS.SETUP_PAYROLL]: true,
    [STEPS.CONNECT_BANK]: true,
  });

  useEffect(() => {
    if (!currentStep && workersRequestedTotal !== undefined) {
      if (currentCompany?.salsaOnboardingStatus === SalsaOnboardingStatus.COMPLETED) {
        setContinueToOnboardingDisabled({
          ...isContinueToOnboardingDisabled,
          [STEPS.ADD_WORKER]: false,
          [STEPS.SETUP_PAYROLL]: false,
        });
        setCurrentStep(STEPS.CONNECT_BANK);
      } else if (workersRequestedTotal) {
        setContinueToOnboardingDisabled({
          ...isContinueToOnboardingDisabled,
          [STEPS.ADD_WORKER]: false,
        });
        setCurrentStep(STEPS.SETUP_PAYROLL);
      } else {
        setShowWelcomeMessage(true);
        setCurrentStep(STEPS.ADD_WORKER);
      }
    }
  }, [workersRequestedTotal]);

  useEffect(() => {
    if (!currentCompany?.isOnboardingComplete) {
      fetchSingleWorker();
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (connectBankUnblockCondition(currentCompany)) {
      handleOnboardingDisabledChange(false, STEPS.SETUP_PAYROLL);
    }
  }, [currentCompany?.salsaOnboardingStatus, currentStep]);

  const refetchCompanyData = async () => {
    const result = await getCompanyInfo(currentCompany?.companyId || "", true);
    return result.companyInfo;
  };

  const handleOnboardingDisabledChange = (isDisabled: boolean, stepIndex: STEPS) => {
    setContinueToOnboardingDisabled((prev) => ({ ...prev, [stepIndex]: isDisabled }));
  };

  const connectBankUnblockCondition = (company?: CompanyResponseDto | null) => {
    const result = company?.salsaOnboardingStatus === SalsaOnboardingStatus.COMPLETED;
    return result;
  };

  const handleBankAgreementSubmit = async () => {
    try {
      setLoading(true);
      await mutationPlaidControllerAcceptDisclosure()();
      await refetchCompanyData();
    } catch (error) {
      showErrorModal(error);
    } finally {
      setLoading(false);
    }
  };

  const moveToWorkersStep = () => {
    setCurrentStep(STEPS.ADD_WORKER);
  };
  const moveToOnboardingStep = () => {
    if (!isContinueToOnboardingDisabled[STEPS.ADD_WORKER]) {
      setCurrentStep(STEPS.SETUP_PAYROLL);
    }
  };

  const moveToBankAccountsStep = () => {
    if (!isContinueToOnboardingDisabled[STEPS.SETUP_PAYROLL]) {
      setCurrentStep(STEPS.CONNECT_BANK);
    }
  };

  const handleContinueToBankAccount = () => {
    onSalsaOnboardingComplete();
  };

  const onSalsaOnboardingComplete = async () => {
    setLoading(true);
    //Our company might not have updated status right away, we need to wait for it to become completed
    const result = await getDeferredData(refetchCompanyData, connectBankUnblockCondition, 2000);
    if (connectBankUnblockCondition(result)) {
      saveCompanyToStore(result);
      setCurrentStep(STEPS.CONNECT_BANK);
    } else {
      showErrorModal();
    }

    setLoading(false);
  };

  const fetchSingleWorker = async () => {
    setLoading(true);
    const employeesListRes = await mutationEmployeesControllerListEmployeesByFilter({
      page: "1",
      perPage: "1",
      searchString: "",
    })();

    setWorkersRequestedTotal(employeesListRes?.total || 0);
    setLoading(false);
  };
  const handleWelcomeComponentContinueClick = () => {
    setShowWelcomeMessage(false);
  };

  return {
    isLoading,
    currentStep,
    actions: {
      moveToWorkersStep,
      moveToOnboardingStep,
      moveToBankAccountsStep,
      handleContinueToBankAccount,
      handleWelcomeComponentContinueClick,
      handleBankAgreementSubmit,
      handleOnboardingDisabledChange,
    },
    pageData: {
      isContinueToOnboardingDisabled,
      showWelcomeMessage,
    },
  };
};
