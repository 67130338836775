import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import moment from "moment";

import { CompanyEntityType } from "types/BETypes";
import SelectOption from "types/SelectOption";
import { TStates } from "types/States";
import { MAX_INPUT_LENGTH } from "constants/form";
import { StateSelectOptions, zipInputFormat } from "constants/shared";
import { companyTypeSelectOptions } from "constants/shared/companyTypes";
import { getFieldError, getPhoneNumberOrNull } from "helpers";

import {
  DatePicker,
  FormLabelSmall,
  Input,
  InputContainer,
  InputGroupContainerHalved,
  NumberInput,
  PhoneInput,
} from "uikit";
import EinInput from "uikit/Input/EinInput";
import { renderExternalLabel } from "uikit/Input/helpers";
import CustomSelect from "uikit/Input/Select";

import { BusinessInformationFormType } from "./validationSchema";
import { Container } from "./styles";
interface IProps {
  className?: string;
}

export const BusinessInformationForm: FC<IProps> = ({ className }) => {
  const translationPrefix = `components.business_owner_structure.shared.inputs_business`;

  const { t } = useTranslation();
  const formikContext = useFormikContext<BusinessInformationFormType>();
  const { values, setFieldValue, handleChange } = formikContext;

  const companyTypeSelectOptionsTranslated = companyTypeSelectOptions.map((item) => ({
    ...item,
    label: t(item.label),
  }));

  return (
    <Container className={className}>
      <InputGroupContainerHalved>
        <InputContainer>
          <FormLabelSmall>
            <Trans i18nKey={`${translationPrefix}.company_name.label`} />
          </FormLabelSmall>

          <Input
            name="companyName"
            onChange={(e) => setFieldValue("companyName", e.currentTarget.value.trimStart())}
            onBlur={(e) => setFieldValue("companyName", e.currentTarget.value.trimEnd())}
            value={values.companyName}
            error={getFieldError("companyName", formikContext, {
              field: t(`${translationPrefix}.company_name.label`),
              maxInputLength: MAX_INPUT_LENGTH,
            })}
            errorDataTestId="error-company_name"
            data-testid="company_name_input"
            placeholder={t(`common.input.placeholder_base`, {
              field: t(`${translationPrefix}.company_name.label`),
            })}
          />
        </InputContainer>

        <InputContainer>
          <FormLabelSmall>
            <Trans i18nKey={`${translationPrefix}.operating_name.label`} />
          </FormLabelSmall>

          <Input
            name="operatingName"
            onChange={(e) => setFieldValue("operatingName", e.currentTarget.value.trimStart())}
            onBlur={(e) => setFieldValue("operatingName", e.currentTarget.value.trimEnd())}
            value={values.operatingName}
            error={getFieldError("operatingName", formikContext, {
              field: t(`${translationPrefix}.operating_name.label`),
              maxInputLength: MAX_INPUT_LENGTH,
            })}
            errorDataTestId="error-operating_name"
            data-testid="operating_name_input"
            placeholder={t(`common.input.placeholder_base`, {
              field: t(`${translationPrefix}.operating_name.label`),
            })}
          />
        </InputContainer>
      </InputGroupContainerHalved>

      <InputContainer>
        <FormLabelSmall>
          <Trans i18nKey={`${translationPrefix}.ownership_percentage.label`} />
        </FormLabelSmall>
        <NumberInput
          name="ownershipPercentage"
          value={String(values.ownershipPercentage)}
          onChange={(e) => setFieldValue("ownershipPercentage", e)}
          error={getFieldError("ownershipPercentage", formikContext, {
            field: t(`${translationPrefix}.ownership_percentage.label`),
          })}
          placeholder={t(`common.input.placeholder_base`, {
            field: t(`${translationPrefix}.ownership_percentage.label`),
          })}
          data-testid="ownership_percentage_input"
          units={"%"}
        />
      </InputContainer>

      <InputGroupContainerHalved>
        <InputContainer>
          <FormLabelSmall>
            <Trans i18nKey={`${translationPrefix}.business_formation_date.label`} />
          </FormLabelSmall>

          <DatePicker
            useMothsYearsHeader={true}
            name="businessFormationDate"
            selected={values.businessFormationDate || null}
            onChange={(e) => setFieldValue("businessFormationDate", e)}
            error={getFieldError("businessFormationDate", formikContext, {
              field: t(`${translationPrefix}.business_formation_date.label`),
            })}
            maxDate={new Date()}
            minDate={moment().subtract(100, "year").toDate()}
            errorDataTestId="error-business_formation_date"
            data-testid="business_formation_date_input"
            placeholderText={t(`common.input.placeholder_base`, {
              field: t(`${translationPrefix}.business_formation_date.label`),
            })}
          />
        </InputContainer>

        <InputContainer>
          <FormLabelSmall>
            <Trans i18nKey={`${translationPrefix}.state_of_incorporation.label`} />
          </FormLabelSmall>

          <CustomSelect<SelectOption<TStates>>
            name="stateOfIncorporation"
            onChange={(option) => setFieldValue("stateOfIncorporation", option)}
            value={values.stateOfIncorporation as any}
            options={StateSelectOptions}
            error={getFieldError("stateOfIncorporation.value", formikContext, {
              field: t(`${translationPrefix}.state_of_incorporation.label`),
            })}
            isSearchable={true}
            errorDataTestId="error-state_of_incorporation"
            data-testid="state_of_incorporation_input"
            placeholder={t(`common.input.placeholder_base`, {
              field: t(`${translationPrefix}.state_of_incorporation.label`),
            })}
          />
        </InputContainer>
      </InputGroupContainerHalved>

      <InputGroupContainerHalved>
        <InputContainer>
          <FormLabelSmall>
            <Trans i18nKey={`${translationPrefix}.business_structure.label`} />
          </FormLabelSmall>

          <CustomSelect<SelectOption<CompanyEntityType>>
            name="businessStructure"
            onChange={(option) => setFieldValue("businessStructure", option)}
            value={
              (values.businessStructure
                ? {
                    ...values.businessStructure,
                    label: t(`${values.businessStructure.label}`),
                  }
                : values.businessStructure) as any
            }
            options={companyTypeSelectOptionsTranslated}
            error={getFieldError("businessStructure.value", formikContext, {
              field: t(`${translationPrefix}.business_structure.label`),
            })}
            isSearchable={true}
            errorDataTestId="error-business_structure"
            data-testid="business_structure_input"
            placeholder={t(`common.input.placeholder_base`, {
              field: t(`${translationPrefix}.business_structure.label`),
            })}
          />
        </InputContainer>

        <InputContainer>
          <FormLabelSmall>
            <Trans i18nKey={`${translationPrefix}.business_phone.label`} />
          </FormLabelSmall>

          <PhoneInput
            name="businessPhone"
            onChange={(value) =>
              setFieldValue("businessPhone", getPhoneNumberOrNull(value as string))
            }
            value={values.businessPhone || undefined}
            error={getFieldError("businessPhone", formikContext, {
              field: t(`${translationPrefix}.business_phone.label`),
            })}
            errorDataTestId="error-business_phone"
            data-testid="business_phone_input"
          />
        </InputContainer>
      </InputGroupContainerHalved>

      <InputGroupContainerHalved>
        <InputContainer>
          <FormLabelSmall>
            <Trans i18nKey={`${translationPrefix}.business_email.label`} />
          </FormLabelSmall>
          <Input
            name="email"
            onChange={(e) => setFieldValue("email", e.currentTarget.value.split(" ").join(""))}
            value={values.email}
            placeholder={t(`common.input.placeholder_base`, {
              field: t(`${translationPrefix}.business_email.label`),
            })}
            error={getFieldError("email", formikContext, {
              field: t(`${translationPrefix}.business_email.label`),
              maxInputLength: MAX_INPUT_LENGTH,
            })}
            data-testid="business_email_input"
          />
        </InputContainer>

        <InputContainer>
          <FormLabelSmall>
            <Trans i18nKey={`${translationPrefix}.ein.label`} />
          </FormLabelSmall>

          <EinInput
            onChange={(value: string) => setFieldValue("ein", value)}
            error={getFieldError("ein", formikContext, {
              field: t(`${translationPrefix}.ein.label_short`),
            })}
            value={values.ein || ""}
            placeholder={t(`${translationPrefix}.ein.placeholder`)}
            data-testid="ein_input"
          />
        </InputContainer>
      </InputGroupContainerHalved>

      <InputGroupContainerHalved>
        <InputContainer>
          <FormLabelSmall>
            <Trans i18nKey={`${translationPrefix}.address.label`} />
          </FormLabelSmall>
          <Input
            name="address"
            onChange={handleChange}
            value={values.address}
            error={getFieldError("address", formikContext, {
              field: t(`${translationPrefix}.address.label`),
              maxInputLength: MAX_INPUT_LENGTH,
            })}
            errorDataTestId="error-address"
            data-testid="address_input"
            placeholder={t(`common.input.placeholder_base`, {
              field: t(`${translationPrefix}.address.label`),
            })}
          />
        </InputContainer>

        <InputContainer>
          <FormLabelSmall>
            {renderExternalLabel(<Trans i18nKey={`${translationPrefix}.suite.label`} />, true)}
          </FormLabelSmall>
          <Input
            name="suite"
            onChange={handleChange}
            value={values.suite}
            error={getFieldError("suite", formikContext, {
              field: t(`${translationPrefix}.address.label`),
              maxInputLength: MAX_INPUT_LENGTH,
            })}
            errorDataTestId="error-suite"
            data-testid="suite_input"
            placeholder={t(`common.input.placeholder_base`, {
              field: t(`${translationPrefix}.suite.label`),
            })}
          />
        </InputContainer>
      </InputGroupContainerHalved>

      <InputGroupContainerHalved>
        <InputContainer>
          <FormLabelSmall>
            <Trans i18nKey={`${translationPrefix}.city.label`} />
          </FormLabelSmall>

          <Input
            name="city"
            onChange={handleChange}
            value={values.city || ""}
            error={getFieldError("city", formikContext, {
              field: t(`${translationPrefix}.city.label`),
              maxInputLength: MAX_INPUT_LENGTH,
            })}
            errorDataTestId="error-city"
            data-testid="city_input"
            placeholder={t(`common.input.placeholder_base`, {
              field: t(`${translationPrefix}.city.label`),
            })}
          />
        </InputContainer>

        <InputContainer>
          <InputGroupContainerHalved>
            <InputContainer>
              <FormLabelSmall>
                <Trans i18nKey={`${translationPrefix}.state.label`} />
              </FormLabelSmall>
              <CustomSelect<SelectOption<TStates>>
                name="state"
                onChange={(option) => setFieldValue("state", option)}
                value={values.state as any}
                options={StateSelectOptions}
                error={getFieldError("state.value", formikContext, {
                  field: t(`${translationPrefix}.state.label`),
                })}
                isSearchable={true}
                errorDataTestId="error-state"
                data-testid="state_input"
                placeholder={t(`common.input.placeholder_base`, {
                  field: t(`${translationPrefix}.state.label`),
                })}
              />
            </InputContainer>

            <InputContainer>
              <FormLabelSmall>
                <Trans i18nKey={`${translationPrefix}.zip.label`} />
              </FormLabelSmall>
              <NumberInput
                name="zip"
                onChange={(val) => setFieldValue("zip", val.toString().split(" ").join(""))}
                value={values.zip}
                error={getFieldError("zip", formikContext, {
                  field: t(`${translationPrefix}.zip.label`),
                })}
                errorDataTestId="error-zip"
                data-testid="zip_input"
                format={zipInputFormat}
                placeholder={t(`common.input.placeholder_base`, {
                  field: t(`${translationPrefix}.zip.label`),
                })}
              />
            </InputContainer>
          </InputGroupContainerHalved>
        </InputContainer>
      </InputGroupContainerHalved>
    </Container>
  );
};
