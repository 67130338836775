import { useEffect, useState } from "react";
import { useAppSelector } from "store/hooks";
import { companyMetadataSelector, userMetadataSelector } from "store/selectors";

import { KybStatus } from "types/BETypes";
import { getErrorMessageFromResponse } from "helpers/shared/errors";
import { isKybRejected } from "helpers/shared/kyb";
import useAuth from "hooks/useAuth";
import { EKycResponseType } from "components/CompanyBankAccountComponents/employee/KYCVerification/types";

import {
  GetBusinessStructureResponseDto,
  KYBErrorResponseDto,
  mutationBusinessBankAccountsControllerRunKyb,
  queryBusinessBankAccountsControllerGetKybErrors,
} from "utils/swagger_react_query";

import { canBusinessRerunKyb, getAllKeyManagers, getKybResponseType } from "./helpers";
import { IUseKybVerificationParams } from "./types";

export const useKybVerification = ({
  onKybSuccess,
  onStatusSectionPrimaryBtnClick,
  businessStructure,
  showResponseTypeSectionOnMount,
}: IUseKybVerificationParams) => {
  const currentUser = useAppSelector(userMetadataSelector);
  const currentCompany = useAppSelector(companyMetadataSelector);
  const { getCompanyInfo, getCurrentUser } = useAuth();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [responseType, setResponseType] = useState<EKycResponseType | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [kybErrors, setKybErrors] = useState<KYBErrorResponseDto[]>([]);

  useEffect(() => {
    if (isKybRejected(currentCompany) && showResponseTypeSectionOnMount) {
      if (currentCompany?.existsPaidBankAccount) {
        setResponseType(EKycResponseType.ERROR_BANK_ACCOUNT_CREATED);
      } else {
        setResponseType(EKycResponseType.ERROR);
      }
    }
  }, [businessStructure, showResponseTypeSectionOnMount]);

  const refetchCurrentUserIfEmailMatch = async (email: string) => {
    const isKeyManager = getAllKeyManagers(businessStructure).some(
      (keyManager) => keyManager.email === email,
    );
    const isIndividualOwner = businessStructure?.owners?.some((owner) => owner.email === email);
    if (isKeyManager || isIndividualOwner) {
      return await getCurrentUser();
    }
  };

  const handleKybSubmit = async () => {
    try {
      setIsSubmitting(true);
      if (isKybRejected(currentCompany)) {
        const kybErrorsRes = await queryBusinessBankAccountsControllerGetKybErrors();
        setKybErrors(kybErrorsRes);
        const canRerun = canBusinessRerunKyb(businessStructure, kybErrorsRes);
        if (!canRerun) {
          setResponseType(EKycResponseType.INCORRECT_INFO);
          return;
        }
      }

      const kybResponseStatus = await mutationBusinessBankAccountsControllerRunKyb()();
      const status = getKybResponseType(kybResponseStatus.status as KybStatus);
      if (status === EKycResponseType.ERROR) {
        const kybErrors = await queryBusinessBankAccountsControllerGetKybErrors();
        setKybErrors(kybErrors);
      }
      await getCompanyInfo(currentCompany?.companyId || "", true);
      if (!status) throw new Error();
      setResponseType(status);
      refetchCurrentUserIfEmailMatch(currentUser?.email || "");
      onKybSuccess?.();
    } catch (error) {
      const _error = getErrorMessageFromResponse(error);
      setErrorMessage(_error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleStatusSectionPrimaryBtnClick = (
    businessStructure: GetBusinessStructureResponseDto,
  ) => {
    if (isKybRejected(currentCompany) && responseType) {
      setResponseType(null);
      setKybErrors([]);
      setErrorMessage(null);
    }
    onStatusSectionPrimaryBtnClick?.(responseType as EKycResponseType, businessStructure);
  };

  return {
    isSubmitting,
    responseType,
    errorMessage,
    handleKybSubmit,
    handleStatusSectionPrimaryBtnClick,
    kybErrors,
  };
};
